/**
 * @author Rehan Rizvi
 * @file QuesAnsBasedOnActivity Component
 * @flow
 */
import React, { Component } from 'react';
import Axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import IdleTimerComponent from '../../Common/IdleTimeCheck/IdleTimerComponent';
import { STRINGS } from '../../../shared/constants/data';
import { getData } from '../../../shared/utils/storage.util';
import CONFIG from '../../../configuration';
import AlertModal from '../../../shared/modals/AlertModal';
import AdditionalQuestionModal from '../../../shared/modals/QuestionModal';
import { spinnerService } from '../../../shared/services/spinner.service';
import { CallApi } from '../../../shared/services/api.service';
import ImagePrevew from '../../../shared/modals/ImagePrevew';
import ReasonModal from '../../../shared/modals/ReasonModal';
import {
    formatDate,
    showObservation,
    checkFileType,
    getAuditId,
    checkVideoType
} from '../../../shared/utils/helper.util';
import AddQuesModal from '../../../shared/modals/AddQuesModal';
import QuantifiableModal from '../../../shared/modals/DvHeightsModal';
import QuantifiableModalDiagnostic from '../../../shared/modals/QuantifiableModal';
// import QuantifiableModal from '../../../shared/modals/QuantifiableModal';
import { serverCalls } from '../../../shared/services/serverCalls.service';
import { logoutUnauthorizedUser } from '../../../shared/utils/auth.util';
import ImageVideoSelectionModal from '../../../shared/modals/ImageVideoSelectionModal';
import Thumbnail from '../../Common/Thumbnail/Thumbnail';
import CostEvaluateModal from '../../../shared/modals/CostEvaluateModal';
import MH_QuesModal from '../../../shared/modals/Mh_QuesModal';
import LANGUAGECONST from '../../../shared/constants/language';
import { languageData } from '../../../redux/actions/languageAction';

const APPLICABLE_TABS = ['PM Quality Assessment', 'Machine Health'];

class QuestionAnsBasedComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasMeasurements: {},
            isModalOpen: false,
            checkDataChange: false,
            taskInfo: {},
            QuesList: [],
            confirmation: false,
            currentTabName: null,
            heading: [],
            selectedType: null,
            isSubmitClicked: false,
            additionalQuestions: [],
            imageArr: [],
            questionData: {},
            index: null,
            showImageModel: false,
            quesIndex: null,
            imgObj: null,
            imgIndex: null,
            selectedQuestionId: '',
            currentQuesIndex: null,
            isQuantifiableModalOpen: false,
            submitObj: {},
            openReasonModal: false,
            showAddQuesModel: false,
            isUploadFile: false,
            openCostEvaluateModal: false,
            uploadObj: null,
            isMH_QuesModalOpen: false,
            quesTypeName: null,
            PMQualityOptions: [
                { label: 'Yes', value: 'Pass' },
                { label: 'No', value: 'Major NC' }
            ],
            DVOptions: [],
            clickToAnswer: 'srv-validation-message',
            limits: {},
            questions: []
        };
    }

    fetchLimits = async (assetName) => {
        try {
            const config = {
                headers: {
                    authorization: `Bearer ${getData('token')}`
                }
            };

            const response = await Axios.get(
                `${CONFIG.getLimits}?assetName=${encodeURIComponent(assetName)}`,
                config
            );

            if (response.data.success && response.data.response.length > 0) {
                const limitsData = {};
                const fetchedQuestions = response.data.response;
                response.data.response.forEach((question) => {
                    const limitKeyMin = `${question.description}_min`;
                    const limitKeyMax = `${question.description}_max`;

                    limitsData[limitKeyMin] = question.min_limit || '';
                    limitsData[limitKeyMax] = question.max_limit || '';
                });
                this.setState({ limits: limitsData, questions: fetchedQuestions });
            }
        } catch (error) {
            console.error('Error fetching limits:', error);
        }
    };

    fetchComments = async (task_id) => {
        try {
            const config = {
                headers: {
                    authorization: `Bearer ${getData('token')}`
                }
            };

            const response = await Axios.get(
                `${CONFIG.getComments}?task_id=${task_id}&long_heading=Heights`,
                config
            );

            if (response.data.success && response.data.response) {
                return response.data.response.comments;
            } else {
                console.warn('No comments found');
                return [];
            }
        } catch (error) {
            console.error('Error fetching comments:', error);
            return [];
        }
    };

    componentDidUpdate = async (prevProps) => {
        const { taskInfo, QuesList } = this.props;

        if (taskInfo.task_id !== prevProps.taskInfo.task_id || QuesList !== prevProps.QuesList) {
            let updatedQuesList = [...QuesList];

            await this.fetchLimits(taskInfo.asset);

            const { questions } = this.state;
            const existingShortQues = new Set(updatedQuesList.map((q) => q.short_ques));
            let op_numb = 10;

            const commentsArray = await this.fetchComments(taskInfo.task_id);
            const commentsMap = {};
            commentsArray.forEach((comment) => {
                commentsMap[comment.short_ques] = comment;
            });

            for (const question of questions) {
                this.state.hasMeasurements[question.description] = false;

                if (!existingShortQues.has(question.description)) {
                    const commentData = commentsMap[question.description] || {};
                    if (taskInfo.heights === 1) {
                        updatedQuesList.push({
                            long_heading: 'Heights',
                            short_ques: question.description,
                            long_ques: question.long_description,
                            operation_numb: op_numb,
                            auditor_comments: commentData.auditor_comments || '',
                            reviewer_comments: commentData.reviewer_comments || '',
                            reviewer_remark: commentData.reviewer_remark || '',
                            audit_id: taskInfo.audit_id
                        });
                        op_numb += 10;
                    }
                }
            }

            this.getHeading(taskInfo || {}, updatedQuesList || []);
        }
    };

    /**
     * @description This function sets value of tabs according task questions.
     * @param taskInfo It has info regarding task
     * @param QuesList It consists list of all questions regarding that task
     */
    getHeading = (taskInfo, QuesList) => {
        let { currentTabName } = this.state;
        let allHeading = QuesList.map((quesObj) => quesObj['long_heading']);
        let heading = allHeading.filter(
            (filterHeading, index) => allHeading.indexOf(filterHeading) === index
        );
        this.setState({
            heading,
            taskInfo,
            QuesList,
            currentTabName: currentTabName ? currentTabName : heading[0]
        });
    };

    /**
     * @description This function updates the current tab value.
     * @param currentTabName It has value of currently selected tab
     */
    tabChange = (currentTabName) => {
        this.setState({ currentTabName });
    };

    /**
     * @description This function updates the current tab value.
     * @param event It has value of selected event
     */
    tabChangeName = (event) => {
        this.setState({ currentTabName: event.target.value });
    };

    /**
     * @description This function sets value of fields.
     */
    handleChange = (event) => {
        let { id, name, value } = event.target;
        let { QuesList } = this.state;
        QuesList[id][name] = value;
        this.setState({ QuesList, checkDataChange: true });
    };

    /**
     * @description This function restrict alphabets and special characters and only allows numbers upto two decimal places.
     */
    restrictToNumber = (event, data) => {
        let { value } = event.target;
        let { currentTabName } = this.state;
        let numRejex = /^\d*\.?\d{0,2}$/;
        if (numRejex.test(value)) {
            if (currentTabName == 'General Audit' && data.long_ques === 'Availability (%)') {
                if (value <= 100) {
                    this.handleChange(event);
                }
            } else {
                this.handleChange(event);
            }
        }
    };

    /**
     * @description This function set character limit on fields
     */
    handleChangeWithLength = (event) => {
        let { value } = event.target;
        if (value.length <= 400) {
            this.handleChange(event);
        }
    };

    /**
     * @description This function formats data for reviewer save and submit
     */
    makeDataFormatForReviewer = (quesArr, type) => {
        let { taskInfo } = this.state;
        let quesData = quesArr.map((quesObj) => {
            let { validation, min, max, isQuant } = quesObj;
            if (
                type == 'submit' &&
                taskInfo.task_status == 2 &&
                min &&
                max &&
                validation &&
                isQuant == 'true'
            ) {
                // quesObj.validation = (parseFloat(validation) >= parseFloat(min) && parseFloat(validation) <= parseFloat(max)) ? "Pass" : "Major NC";
            }
            return quesObj;
        });
        return quesData;
    };

    setDataValue = (index, quesList) => {
        let item = quesList[index];
        item.isQuesCompleted = 0;
        this.setState({
            QuesList: quesList
        });
    };
    /**
     * @description This function validates the values of data object on submit
     * @param quesList It has data objects to be validated
     */
    _questionFormValidator(quesList, type) {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let error = null;
        let { checkDataChange, taskInfo } = this.state;
        let { task_status } = taskInfo ? taskInfo : {};
        this.setState({
            isSubmitClicked: true
        });
        // if (checkDataChange) {
        //   return error = 'Please save before submit.';
        // }
        for (let i = 0; i < quesList.length; i++) {
            let item = quesList[i];
            if (item.long_heading === 'Diagnostic Visit') {
                if (
                    (item.validation === 'Immediate Attention Required by Plant' &&
                        (item.auditor_comments === '' || item.auditor_comments === null)) ||
                    item.validation === null
                ) {
                    let msg = lang.STRINGS.MandatoryFields;
                    let customMsg = msg.slice(0, msg.length - 1);
                    return (error = `${customMsg} for “${item.long_ques}”`);
                }
                continue;
            }
            if (
                item.long_heading === 'General Utilities' ||
                item.long_heading === 'General Facilities'
            ) {
                if (item.isApplicable == 1) {
                    if (
                        (task_status == 1 &&
                            (item.auditor_flag == 1 || item.auditor_flag == 2) &&
                            !item.observation) ||
                        (task_status == 2 && !item.observation)
                    ) {
                        this.setDataValue(i, quesList);
                        if (type === 'submit')
                            return (error = `${lang.STRINGS.OBSERVATIONREQ} ${item.long_ques}`);
                    }
                } else {
                    if (item.auditor_flag == 1 && item.url.length == 0 && task_status == 1) {
                        this.setState({ currentTabName: item.long_heading });
                        this.setDataValue(i, quesList);
                        if (type === 'submit')
                            return (error = `${lang.STRINGS.IMAGEREQ} “${item.long_ques}”`);
                    }
                    if (
                        item.auditor_flag == 2 &&
                        (!item.validation || (task_status == 2 && !item.validation))
                    ) {
                        this.setState({ currentTabName: item.long_heading });
                        this.setDataValue(i, quesList);
                        if (type === 'submit')
                            return (error = `${lang.STRINGS.QUESTIONNAIREREQ} “${item.long_ques}”`);
                    }
                    if (
                        task_status == 1 &&
                        item.auditor_flag == 2 &&
                        !!item.validation &&
                        item.validation != 'Pass' &&
                        !item.observation
                    ) {
                        this.setState({ currentTabName: item.long_heading });
                        this.setDataValue(i, quesList);
                        if (type === 'submit')
                            return (error = `${lang.STRINGS.OBSERVATIONREQ} ${item.long_ques}`);
                    }
                    if (
                        task_status == 1 &&
                        item.auditor_flag == 2 &&
                        !!item.validation &&
                        item.validation != 'Pass' &&
                        !item.auditor_comments
                    ) {
                        this.setState({ currentTabName: item.long_heading });
                        this.setDataValue(i, quesList);
                        if (type === 'submit')
                            return (error = `${lang.STRINGS.IMPACTREQ} ${item.long_ques}`);
                    }

                    if (
                        task_status == 2 &&
                        (item.auditor_flag == 1 ||
                            item.auditor_flag == 2 ||
                            item.auditor_flag == 3) &&
                        !item.validation
                    ) {
                        this.setDataValue(i, quesList);
                        this.setState({ currentTabName: item.long_heading });
                        if (type === 'submit')
                            return (error = `${lang.STRINGS.QUESTIONNAIREREQ} “${item.long_ques}”`);
                    }

                    if (
                        task_status == 2 &&
                        item.auditor_flag == 1 &&
                        item.validation != 'Pass' &&
                        !item.url.length
                    ) {
                        this.setState({ currentTabName: item.long_heading });
                        this.setDataValue(i, quesList);
                        if (type === 'submit')
                            return (error = `${lang.STRINGS.IMAGEREQ} “${item.long_ques}”`);
                    }

                    if (
                        task_status == 2 &&
                        (item.auditor_flag == 1 ||
                            item.auditor_flag == 2 ||
                            item.auditor_flag == 3) &&
                        item.validation != 'Pass' &&
                        !item.observation
                    ) {
                        this.setState({ currentTabName: item.long_heading });
                        this.setDataValue(i, quesList);
                        if (type === 'submit')
                            return (error = `${lang.STRINGS.OBSERVATIONREQ} “${item.long_ques}”`);
                    }
                    // if (task_status == 1 && (item.auditor_flag == 1 || item.auditor_flag == 2) && item.validation != "Pass" &&
                    //   !item.auditor_comments) {
                    //   this.setState({ currentTabName: item.long_heading });
                    //   return error = `Impact is required for ${item.long_ques}`;
                    // }
                    if (
                        task_status == 2 &&
                        (item.auditor_flag == 1 ||
                            item.auditor_flag == 2 ||
                            item.auditor_flag == 3) &&
                        item.validation != 'Pass' &&
                        (!item.auditor_comments || !item.reviewer_comments)
                    ) {
                        this.setState({ currentTabName: item.long_heading });
                        this.setDataValue(i, quesList);
                        if (type === 'submit')
                            return (error = `${lang.STRINGS.IMPACTRECOMMENDATIONREQ} “${item.long_ques}”`);
                    }
                }
            } else {
                if (item.isApplicable == 1) {
                    if (item.url.length == 0 && item.long_heading !== 'General Audit') {
                        this.setState({ currentTabName: item.long_heading });
                        this.setDataValue(i, quesList);
                        if (type === 'submit')
                            return (error = `${lang.STRINGS.IMAGEREQ} “${
                                item.operation_numb
                                    ? lang.LABLES.TASKNO + ': ' + item.operation_numb + ' – '
                                    : ''
                            }${item.short_ques ? item.short_ques + ' – ' : ''}${item.long_ques}”`);
                    }
                    if (!item.observation) {
                        this.setDataValue(i, quesList);
                        if (type === 'submit')
                            return (error = `${lang.STRINGS.OBSERVATIONREQ} ${item.long_ques}`);
                    }
                } else {
                    if (!item.validation) {
                        this.setState({ currentTabName: item.long_heading });
                        if (item.isQuant === 'false') {
                            item.isQuesCompleted = 0;
                            this.setState({
                                QuesList: quesList
                            });
                            if (type === 'submit')
                                return (error = `${lang.STRINGS.QUESTIONNAIREREQ} “${
                                    item.operation_numb
                                        ? lang.LABLES.TASKNO + ': ' + item.operation_numb + ' – '
                                        : ''
                                }${item.short_ques ? item.short_ques + ' – ' : ''}${
                                    item.long_ques
                                }”`);
                        } else if (item.isQuant === 'true') {
                            item.isQuesCompleted = 0;
                            this.setState({
                                QuesList: quesList
                            });
                            if (type === 'submit')
                                return (error = `${lang.STRINGS.VALUEREQ} “${
                                    item.operation_numb
                                        ? lang.LABLES.TASKNO + ': ' + item.operation_numb + ' – '
                                        : ''
                                }${item.short_ques ? item.short_ques + ' – ' : ''}${
                                    item.long_ques
                                }”`);
                        }
                    } else if (
                        (item.isQuant == 'false' ||
                            item.long_ques ===
                                'Standard PM Resources employed during the Assessment') &&
                        ['Major NC', 'Minor NC'].indexOf(item.validation) > -1 &&
                        item.url.length == 0
                    ) {
                        this.setDataValue(i, quesList);
                        this.setState({ currentTabName: item.long_heading });
                        if (type === 'submit')
                            return (error = `${lang.STRINGS.IMAGEREQ} “${
                                item.operation_numb
                                    ? lang.LABLES.TASKNO + ': ' + item.operation_numb + ' – '
                                    : ''
                            }${item.short_ques ? item.short_ques + ' – ' : ''}${item.long_ques}”`);
                    } else if (
                        (item.isQuant === 'false' ||
                            (item.isQuant === 'true' &&
                                !(
                                    parseFloat(item.validation) >= parseFloat(item.min) &&
                                    parseFloat(item.validation) <= parseFloat(item.max)
                                ) &&
                                task_status == 2)) &&
                        item.validation !== 'Pass' &&
                        item.long_heading != 'General Audit' &&
                        !item.observation
                    ) {
                        this.setDataValue(i, quesList);
                        this.setState({ currentTabName: item.long_heading });
                        if (type === 'submit')
                            return (error = `${lang.STRINGS.OBSERVATIONREQ} “${
                                item.operation_numb
                                    ? lang.LABLES.TASKNO + ': ' + item.operation_numb + ' – '
                                    : ''
                            }${item.short_ques ? item.short_ques + ' – ' : ''}${item.long_ques}”`);
                    } else if (
                        item.validation !== 'Pass' &&
                        item.long_heading != 'General Audit' &&
                        !item.auditor_comments &&
                        (item.isQuant === 'false' ||
                            (item.isQuant === 'true' &&
                                !(
                                    parseFloat(item.validation) >= parseFloat(item.min) &&
                                    parseFloat(item.validation) <= parseFloat(item.max)
                                ) &&
                                task_status == 2))
                    ) {
                        this.setDataValue(i, quesList);
                        this.setState({ currentTabName: item.long_heading });
                        if (type === 'submit')
                            return (error = `${lang.STRINGS.IMPACTREQ} “${
                                item.operation_numb
                                    ? lang.LABLES.TASKNO + ': ' + item.operation_numb + ' – '
                                    : ''
                            }${item.short_ques ? item.short_ques + ' – ' : ''}${item.long_ques}”`);
                    } else if (
                        task_status == 2 &&
                        item.validation !== 'Pass' &&
                        item.long_heading != 'General Audit' &&
                        !item.reviewer_comments &&
                        (item.isQuant === 'false' ||
                            (item.isQuant === 'true' &&
                                !(
                                    parseFloat(item.validation) >= parseFloat(item.min) &&
                                    parseFloat(item.validation) <= parseFloat(item.max)
                                ) &&
                                task_status == 2))
                    ) {
                        this.setDataValue(i, quesList);
                        this.setState({ currentTabName: item.long_heading });
                        if (type === 'submit')
                            return (error = `${lang.STRINGS.RECOMMENDATIONREQ} “${
                                item.operation_numb
                                    ? lang.LABLES.TASKNO + ': ' + item.operation_numb + ' – '
                                    : ''
                            }${item.short_ques ? item.short_ques + ' – ' : ''}${item.long_ques}”`);
                    } else if (
                        item.validation !== 'Pass' &&
                        item.long_heading == 'General Audit' &&
                        !(item.start_date && item.end_date)
                    ) {
                        this.setDataValue(i, quesList);
                        this.setState({ currentTabName: item.long_heading });
                        if (type === 'submit')
                            return (error = `${lang.STRINGS.STARTENDDATEREQ} “${
                                item.operation_numb
                                    ? lang.LABLES.TASKNO + ': ' + item.operation_numb + ' – '
                                    : ''
                            }${item.short_ques ? item.short_ques + ' – ' : ''}${item.long_ques}”`);
                    } else if (
                        item.isQuant === 'true' &&
                        item.validation !== 'Pass' &&
                        item.long_heading != 'General Audit' &&
                        !item.observation
                    ) {
                        this.setDataValue(i, quesList);
                        this.setState({ currentTabName: item.long_heading });
                        if (type === 'submit')
                            return (error = `${lang.STRINGS.OBSERVATIONREQ} “${
                                item.operation_numb
                                    ? lang.LABLES.TASKNO + ': ' + item.operation_numb + ' – '
                                    : ''
                            }${item.short_ques ? item.short_ques + ' – ' : ''}${item.long_ques}”`);
                    } else if (
                        item.isQuant === 'true' &&
                        item.validation !== 'Pass' &&
                        item.long_heading != 'General Audit' &&
                        !item.observation
                    ) {
                        this.setDataValue(i, quesList);
                        this.setState({ currentTabName: item.long_heading });
                        if (type === 'submit')
                            return (error = `${lang.STRINGS.OBSERVATIONREQ} “${
                                item.operation_numb
                                    ? lang.LABLES.TASKNO + ': ' + item.operation_numb + ' – '
                                    : ''
                            }${item.short_ques ? item.short_ques + ' – ' : ''}${item.long_ques}”`);
                    } else if (
                        item.isQuant === 'true' &&
                        item.validation !== 'Pass' &&
                        item.long_heading != 'General Audit' &&
                        !item.auditor_comments
                    ) {
                        this.setDataValue(i, quesList);
                        this.setState({ currentTabName: item.long_heading });
                        if (type === 'submit')
                            return (error = `${lang.STRINGS.IMPACTREQ} “${
                                item.operation_numb
                                    ? lang.LABLES.TASKNO + ': ' + item.operation_numb + ' – '
                                    : ''
                            }${item.short_ques ? item.short_ques + ' – ' : ''}${item.long_ques}”`);
                    }
                }
            }
        }
        return error;
    }

    /**
     * @description This function calls on save and submit data. On save it show preview of
     * data and on submit that task is being assigned to the reviewer. It also get called after being
     * idle for 5 minutes and saves the data.
     */
    submitAns = (type) => {
        let { userData } = getData('loginData');
        let { taskInfo, QuesList, checkDataChange, confirmation } = this.state;
        let { task_id, task_status, reviewer_flag, audit_id } = taskInfo;
        let error = '';
        if (type == 'submit') {
            error = this._questionFormValidator(QuesList, type);
        } else {
            error = this._questionFormValidator(QuesList, type);
            this.setState({ checkDataChange: false, QuesList });
        }
        if (error && type === 'submit') {
            return AlertModal.showAlert(error);
        }
        let quesData = this.makeDataFormatForReviewer(QuesList, type);
        let { role_name } = userData;
        let data = {
            task_id,
            asset_type_id: getAuditId(taskInfo.task_name),
            ques: quesData,
            audit_id,
            task_status: type == 'submit' ? task_status + 1 : task_status,
            reviewer_flag: reviewer_flag ? reviewer_flag : 0,
            ar_reviewer_flag: role_name == 'plant manager' && task_status == 2 ? 1 : 0,
            isEmail: type === 'submit' ? true : false,
            isTaskCompleted: type === 'submit' ? 1 : 0
        };
        if (type == 'save') {
            this.props.submitAns(CONFIG.addTaskQues, data, false, true, type);
        } else if (type == 'idle' && task_status !== 3) {
            this.props.submitAns(CONFIG.addTaskQues, data, false, false, type);
        }
        if (type == 'submit') {
            this.setState({ submitObj: data }, () => {
                if (task_status == 1) {
                    if (!confirmation) {
                        AlertModal.showAlert(
                            LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS
                                .AcceptDeclaration
                        );
                    } else {
                        AlertModal.showAlert('', 'submitAudit', 'onSubmit', this);
                    }
                } else if (task_status == 2) {
                    if (!confirmation) {
                        AlertModal.showAlert(
                            LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS
                                .AcceptDeclaration
                        );
                    } else {
                        this.onSubmit();
                    }
                }
            });
        }
    };

    /**
     * @description This function submits data with suggestion message and
     * the task is being assigned to the reviewer.
     * @param suggestion_msg It has value of sugestion message from auditor, it can be null.
     */
    onSubmit = (suggestion_msg) => {
        const { submitObj } = this.state;
        let updatedSubmitObj = { ...submitObj };
        if (this.state.currentTabName == 'Heights') {
            updatedSubmitObj.isHeights = true;

            for (let key of Object.keys(this.state.hasMeasurements)) {
                if (this.state.hasMeasurements[key] !== true) {
                    return AlertModal.showAlert(
                        LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS
                            .MeasurementsRequired
                    );
                }
            }
        }
        updatedSubmitObj.suggestion_msg = suggestion_msg;
        this.props.submitAns(CONFIG.addTaskQues, updatedSubmitObj, false, true, 'submit');
    };

    /**
     * @description This function uploads the image.
     * @param data It consists data of image file.
     */
    uploadImage = async (index, data) => {
        let { QuesList } = this.state;
        let language = this.props?.langData?.language || 'en';
        spinnerService.show(
            'Oval',
            LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.UploadingImage
        );
        try {
            const config = {
                headers: {
                    authorization: `Bearer ${getData('token')}`,
                    language: language
                }
            };
            let response = await Axios.post(CONFIG.uploadQuesImage, data, config);
            spinnerService.hide('Oval');
            if (response && response.data && response.data.responseCode == 200) {
                QuesList[index]['url'] = QuesList[index]['url']
                    ? QuesList[index]['url'].concat(response.data.response?.url)
                    : response.data.response?.url;
                this.setState({ QuesList });
            } else if (response && response.data && response.data.responseCode == 401) {
                logoutUnauthorizedUser(this, response.data.message);
            } else {
                AlertModal.showAlert(response.data.message);
            }
        } catch (error) {
            spinnerService.hide('Oval');
            AlertModal.showAlert(error.message);
        }
    };

    /**
     * @description This function handle images data.
     * @param data It consists data of image file.
     */
    handleFile = (event, data, type) => {
        this.handleUploadModalClose();
        let { id, name, files } = event.target;
        let { taskInfo } = this.state;
        let { task_id } = taskInfo;
        let { url } = data;
        if (
            files.length &&
            ((type === 'image' && !checkFileType(files[0].type)) ||
                (type === 'video' && !checkVideoType(files[0].type)))
        ) {
            return AlertModal.showAlert(
                LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.UnsupportedFileType
            );
        }
        if ((url && url.length + files.length <= 4) || (!url && files.length <= 4)) {
            let formData = new FormData();
            formData.append('task_id', task_id);
            formData.append('ques_id', event.target.getAttribute('data-id'));
            formData.append('asset_type_id', getAuditId(taskInfo.task_name));
            for (let file of files) {
                formData.append('file', file);
            }
            this.uploadImage(name, formData);
        } else {
            AlertModal.showAlert(
                LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.MaxImages
            );
        }
    };

    openUploadModal = (ev, data, index) => {
        ev.preventDefault();
        this.setState({
            isUploadFile: true,
            uploadObj: { data: data, index: index }
        });
    };

    handleUploadModalClose = () => {
        this.setState({
            isUploadFile: false
        });
    };

    openUploadModal = (ev, data, index) => {
        // console.log("123",ev, data, index)
        ev.preventDefault();
        this.setState({
            isUploadFile: true,
            uploadObj: { data: data, index: index }
        });
    };

    handleUploadModalClose = () => {
        this.setState({
            isUploadFile: false
        });
    };

    /**
     * @description This function sets value of date in QuesList object.
     */
    handleDate = (id, name, value) => {
        let { QuesList } = this.state;
        if (name == 'start_date') {
            QuesList[id][name] = Date.parse(value);
            QuesList[id]['end_date'] = '';
            this.setState({ QuesList, checkDataChange: true });
        } else {
            QuesList[id][name] = Date.parse(value);
            this.setState({ QuesList, checkDataChange: true });
        }
    };

    /**
     * @description This function shows text fields when the question is quantifiable.
     * @param data It consists data of the particular quantifiable question.
     */
    getInput = (index, data) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let { taskInfo, currentTabName, DVOptions } = this.state;
        let { task_status } = taskInfo ? taskInfo : {};
        let { userData } = getData('loginData');
        let isDisabled =
            (userData.role_name === 'auditor' && task_status == 2) || task_status == 3
                ? true
                : false;
        return (
            <React.Fragment>
                <div className="data-upload-col">
                    <a
                        href=""
                        onClick={(evt) => {
                            // if (task_status != 3) {
                            if (currentTabName == 'Diagnostic Visit') {
                                if (DVOptions.length === 0) {
                                    let options = data.subQues[0].option.map((option) => {
                                        return { label: option, value: option };
                                    });
                                    this.setState({
                                        DVOptions: options
                                    });
                                }
                                this.openQuantifiableModal(evt, data, index, 4);
                            } else {
                                this.openQuantifiableModal(evt, data, index, 1);
                            }
                            // }
                        }}
                        className={
                            task_status === 2 && currentTabName === 'Heights'
                                ? ' srv-validation-correct-message'
                                : currentTabName === 'Heights' &&
                                  this.state.hasMeasurements[data.short_ques]
                                ? ' srv-validation-correct-message'
                                : !data.isApplicable
                                ? !!data.validation
                                    ? data.long_heading === 'Diagnostic Visit'
                                        ? data.validation ===
                                          'Immediate Attention Required by Plant'
                                            ? this.state.taskInfo?.task_status === 2 ||
                                              this.state.taskInfo?.task_status === 1
                                                ? !!data.auditor_comments
                                                    ? ' srv-validation-correct-message'
                                                    : ' srv-validation-message'
                                                : ' srv-validation-correct-message'
                                            : data.validation === ''
                                            ? ''
                                            : ' srv-validation-correct-message'
                                        : data.long_heading !== 'General Audit'
                                        ? (task_status != 2 && !!data.validation) ||
                                          (task_status == 2 &&
                                              !!data.validation &&
                                              ((!!data.observation &&
                                                  !!data.auditor_comments &&
                                                  !!data.reviewer_comments) ||
                                                  !!(
                                                      parseFloat(data.validation) >=
                                                          parseFloat(data.min) &&
                                                      parseFloat(data.validation) <=
                                                          parseFloat(data.max)
                                                  )))
                                            ? ' srv-validation-correct-message'
                                            : ' srv-validation-message'
                                        : !!data.start_date && !!data.end_date && !!data.validation
                                        ? ' srv-validation-correct-message'
                                        : ' srv-validation-message'
                                    : ''
                                : !!data.isApplicable
                                ? !!data.observation &&
                                  (data.url.length != 0 ||
                                      data.long_heading === 'General Audit' ||
                                      data.long_heading === 'General Utilities')
                                    ? ' srv-validation-correct-message'
                                    : ' srv-validation-message'
                                : ''
                        }>
                        {lang.STRINGS.ClickToAnswer}
                    </a>
                    <br />
                    <span>{data.validation}</span>
                    <span>
                        {this.costEvaluate(
                            task_status,
                            data.validation,
                            data.isInspected,
                            data.isApplicable,
                            data.ques_id,
                            data.long_heading
                        )}
                    </span>
                    <br />
                    <span>
                        {getData('loggedUser') === taskInfo.reviewer_name &&
                            (task_status == 2 || isDisabled) &&
                            !!data.min &&
                            !!data.max && (
                                <label>
                                    {!data.isApplicable
                                        ? !data.isInspected
                                            ? !!data.validation
                                                ? parseFloat(data.validation) >=
                                                      parseFloat(data.min) &&
                                                  parseFloat(data.validation) <=
                                                      parseFloat(data.max)
                                                    ? lang.LABLES.Pass
                                                    : lang.LABLES.MajorNC
                                                : ''
                                            : lang.LABLES.NotInspected
                                        : lang.LABLES.NotApplicable}
                                </label>
                            )}
                    </span>
                </div>
            </React.Fragment>
        );
        // return (
        //   <div>
        //     <input type="text" className="form-control" id={index}
        //       value={data.validation ? data.validation : ""} disabled={isDisabled}
        //       name="validation" onChange={(event) => this.restrictToNumber(event, data)} />
        //     {((getData("loggedUser") === taskInfo.reviewer_name) && (task_status == 2 || isDisabled) && !!data.min && !!data.max) &&
        //       <label>{parseFloat(data.validation) >= parseFloat(data.min) && parseFloat(data.validation) <= parseFloat(data.max) ? "Pass" : "Major NC"}</label>
        //     }
        //   </div>
        // )
    };

    /**
     * @description This function shows drop-down.
     * @param data It consists data of the particular quantifiable question.
     */
    getSelectBox = (index, data, typeId) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let { PMQualityOptions, taskInfo, currentTabName } = this.state;
        let { task_status } = taskInfo ? taskInfo : {};
        let { userData } = getData('loginData');
        let isDisabled =
            (userData.role_name === 'auditor' && task_status == 2) || task_status == 3
                ? true
                : false;
        return (
            <React.Fragment>
                <div className="data-upload-col">
                    <a
                        href=""
                        onClick={(evt) => {
                            // if (task_status != 3) {
                            this.openQuantifiableModal(evt, data, index, typeId);
                            // }
                        }}
                        className={
                            !data.isApplicable
                                ? !!data.validation ||
                                  (data.long_heading == 'General Utilities' &&
                                      task_status == 2 &&
                                      !!data.validation)
                                    ? data.validation == 'Pass' ||
                                      (currentTabName === 'PM Quality Assessment' &&
                                          data.long_ques !==
                                              'PM Cart Equipped with Tools Required for PM/Repair')
                                        ? data.validation === 'Pass'
                                            ? ' srv-validation-correct-message'
                                            : data.validation != 'Pass' &&
                                              (!data.auditor_comments ||
                                                  !data.observation ||
                                                  (task_status == 2 && !data.reviewer_comments) ||
                                                  !data.url ||
                                                  data.url.length == 0)
                                            ? ' srv-validation-message'
                                            : ' srv-validation-correct-message'
                                        : data.validation != 'Pass' &&
                                          data.auditor_flag != 1 &&
                                          (!data.auditor_comments ||
                                              !data.observation ||
                                              (data.long_heading != 'General Utilities' &&
                                                  data.url.length == 0) ||
                                              (data.long_heading == 'General Utilities' &&
                                                  task_status == 1 &&
                                                  data.auditor_flag == 1 &&
                                                  data.validation != 'Pass' &&
                                                  data.url.length != 0) ||
                                              (task_status == 2 && !data.reviewer_comments))
                                        ? ' srv-validation-message'
                                        : data.long_heading == 'General Utilities' &&
                                          task_status == 2 &&
                                          data.auditor_flag == 1 &&
                                          data.validation != 'Pass' &&
                                          (data.url.length == 0 ||
                                              !data.auditor_comments ||
                                              !data.reviewer_comments ||
                                              !data.observation)
                                        ? ' srv-validation-message'
                                        : ' srv-validation-correct-message'
                                    : (data.long_heading == 'General Utilities' ||
                                          data.long_heading == 'General Facilities') &&
                                      task_status == 1 &&
                                      data.url.length != 0
                                    ? ' srv-validation-correct-message'
                                    : ''
                                : data.isApplicable && data.url.length != 0
                                ? data.long_heading == 'General Audit' ||
                                  data.long_heading == 'General Utilities' ||
                                  data.long_heading == 'General Facilities'
                                    ? ' srv-validation-correct-message'
                                    : ' srv-validation-message'
                                : ''
                        }>
                        {lang.STRINGS.ClickToAnswer}
                    </a>
                    <br />
                    <span>
                        {this.costEvaluate(
                            task_status,
                            data.validation,
                            data.isInspected,
                            data.isApplicable,
                            data.ques_id,
                            data.long_heading
                        )}
                    </span>
                    <br />
                    <span>
                        {getData('loggedUser') === taskInfo.reviewer_name &&
                            taskInfo.task_status == 2 && (
                                <label>
                                    {!data.isApplicable
                                        ? !data.isInspected
                                            ? data.validation
                                            : lang.LABLES.NotInspected
                                        : lang.LABLES.NotApplicable}
                                </label>
                            )}
                    </span>
                </div>
            </React.Fragment>
            // <select className="form-control" id={index} name="validation"
            //   value={data.validation ? data.validation : ""} onChange={this.handleChange} disabled={isDisabled}>
            //   <option disabled value="">Select Option</option>
            //   {PMQualityOptions.map((data, indexKey) => (
            //     <option key={indexKey} value={data.value}>{data.label}</option>
            //   ))}
            // </select>
        );
    };

    openCostEvaluateModal = (evt, ques_id) => {
        evt.preventDefault();
        this.setState({ openCostEvaluateModal: true, selectedQuestionId: ques_id });
    };

    closeCostEvaluateModal = (evt) => {
        if (evt) {
            evt.preventDefault();
        }
        this.setState({ openCostEvaluateModal: false });
    };

    costEvaluate(task_status, validation, isInspected, isApplicable, ques_id, longHeading) {
        if (
            task_status == 2 &&
            validation !== 'Pass' &&
            !isInspected &&
            !isApplicable &&
            longHeading !== 'Diagnostic Visit'
        )
            return (
                <a
                    href=""
                    onClick={(evt) => {
                        this.openCostEvaluateModal(evt, ques_id);
                    }}>
                    Click to Cost Evaluate
                </a>
            );
        return null;
    }

    /**
     * @description This function shows click to answer to open modal consisting all the
     * sub-questions in that question data object.
     * @param data It consists data of the particular quantifiable question.
     */
    AdditionalQuesComponent = (data, index) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let { taskInfo } = this.state;
        let { task_status } = taskInfo ? taskInfo : {};
        return (
            <React.Fragment>
                <div className="data-upload-col">
                    <a
                        href=""
                        onClick={(evt) => {
                            // if (task_status != 3) {
                            this.openQuestionModal(evt, data, index);
                            // }
                        }}
                        className={
                            !data.isApplicable
                                ? data.validation
                                    ? data.validation == 'Pass'
                                        ? ' srv-validation-correct-message'
                                        : !data.isApplicable
                                        ? data.validation != 'Pass' &&
                                          (!data.auditor_comments ||
                                              !data.observation ||
                                              (task_status == 2 && !data.reviewer_comments) ||
                                              !data.url ||
                                              data.url.length == 0)
                                            ? ' srv-validation-message'
                                            : ' srv-validation-correct-message'
                                        : ' srv-validation-correct-message'
                                    : ''
                                : !!data.isApplicable
                                ? !!data.observation &&
                                  (data.url.length != 0 ||
                                      data.long_heading == 'General Audit' ||
                                      data.long_heading == 'General Utilities')
                                    ? ' srv-validation-correct-message'
                                    : ' srv-validation-message'
                                : ''
                        }>
                        {lang.STRINGS.ClickToAnswer}
                    </a>
                    <br />
                    <span>
                        {this.costEvaluate(
                            task_status,
                            data.validation,
                            data.isInspected,
                            data.isApplicable,
                            data.ques_id,
                            data.long_heading
                        )}
                    </span>
                    <br />
                    <span>
                        {getData('loggedUser') === taskInfo.reviewer_name &&
                            taskInfo.task_status == 2 && (
                                <label>
                                    {!data.isApplicable
                                        ? !data.isInspected
                                            ? data.validation
                                            : lang.LABLES.NotInspected
                                        : lang.LABLES.NotApplicable}
                                </label>
                            )}
                    </span>
                </div>
            </React.Fragment>
        );
    };

    MachineHealth_Ques = (data, index) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let { taskInfo } = this.state;
        let { task_status } = taskInfo ? taskInfo : {};
        return (
            <React.Fragment>
                <div className="data-upload-col">
                    <a
                        href=""
                        onClick={(evt) => {
                            // if (task_status != 3) {
                            this.openMH_QuesModal(evt, data, index);
                            // }
                        }}
                        className={
                            !data.isApplicable
                                ? data.validation
                                    ? data.validation == 'Pass'
                                        ? ' srv-validation-correct-message'
                                        : !data.isApplicable
                                        ? data.validation != 'Pass' &&
                                          (!data.auditor_comments ||
                                              !data.observation ||
                                              (task_status == 2 && !data.reviewer_comments) ||
                                              !data.url ||
                                              data.url.length == 0)
                                            ? ' srv-validation-message'
                                            : ' srv-validation-correct-message'
                                        : ' srv-validation-correct-message'
                                    : ''
                                : !!data.isApplicable
                                ? !!data.observation &&
                                  (data.url.length != 0 ||
                                      data.long_heading == 'General Audit' ||
                                      data.long_heading == 'General Utilities')
                                    ? ' srv-validation-correct-message'
                                    : ' srv-validation-message'
                                : ''
                        }>
                        {lang.STRINGS.ClickToAnswer}
                    </a>
                    <br />
                    <span>
                        {this.costEvaluate(
                            task_status,
                            data.validation,
                            data.isInspected,
                            data.isApplicable,
                            data.ques_id,
                            data.long_heading
                        )}
                    </span>
                    <br />
                    <span>
                        {getData('loggedUser') === taskInfo.reviewer_name &&
                            taskInfo.task_status == 2 && (
                                <label>
                                    {!data.isApplicable
                                        ? !data.isInspected
                                            ? data.validation
                                            : lang.LABLES.NotInspected
                                        : lang.LABLES.NotApplicable}
                                </label>
                            )}
                    </span>
                </div>
            </React.Fragment>
        );
    };

    openQuantifiableModal = (evt, selectedQues, index, selectedType) => {
        evt.preventDefault();
        this.setState({
            isQuantifiableModalOpen: true,
            questionData: selectedQues,
            index: index,
            selectedType
        });
    };

    closeQuantifiableModal = () => {
        this.setState({ isQuantifiableModalOpen: false });
    };

    /**
     * @description This function opens sub-question modal.
     * @param selectedQuestion It consists data of the particular quantifiable question.
     */
    openQuestionModal = (evt, selectedQuestion, index) => {
        evt.preventDefault();
        const { ques_id } = selectedQuestion;
        this.setState({
            isModalOpen: true,
            selectedQuestionId: ques_id,
            currentQuesIndex: index
        });
    };

    /**
     * @description This function closes sub-question modal.
     * @param validation It consists deduced validation on the bases on sub-question
     * (e.g Pass, Major NC or Minor NC).
     * @param ques_id It consists id of question whose sub-questions are shown in the modal.
     */
    _oncloseModal = (evt, validation, ques_id) => {
        if (evt) {
            evt.preventDefault();
        }
        if (validation) {
            const { QuesList } = this.state;
            const index = QuesList.findIndex((data) => {
                return data.ques_id === ques_id;
            });
            QuesList[index].validation = validation;
        }
        this.setState({ isModalOpen: false, checkDataChange: true });
    };

    openMH_QuesModal = (evt, selectedQues, index) => {
        evt.preventDefault();
        this.setState({
            isMH_QuesModalOpen: true,
            questionData: selectedQues,
            index: index
        });
    };

    closeMH_QuesModal = () => {
        this.setState({ isMH_QuesModalOpen: false });
    };
    /**
     * @description This function shows date picker to select date.
     * @param data It consists data of question which satisfies the condition to show the date picker.
     */
    getDateSelector = (index, data) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let { taskInfo } = this.state;
        let { task_status } = taskInfo;
        let { userData } = getData('loginData');
        let isDisabled =
            (userData.role_name === 'auditor' && task_status == 2) || task_status == 3
                ? true
                : false;
        return (
            <div className="data-upload-col date-upload-col">
                <DatePicker
                    placeholderText={lang.LABLES.StartDate}
                    selected={data.start_date}
                    className={`form-control ${
                        !data.isApplicable && !!data.validation && !data.start_date
                            ? 'blank-field'
                            : ''
                    }`}
                    dateFormat="MM/dd/yyyy"
                    maxDate={new Date()}
                    minDate={new Date() - 94608000000}
                    onChange={(date) => this.handleDate(index, 'start_date', date)}
                    showMonthDropdown
                    showYearDropdown
                    disabled={isDisabled || !!data.isApplicable}
                    dropdownMode="select"
                    locale={this.props?.langData?.language || 'en'}
                />
                <DatePicker
                    placeholderText={lang.LABLES.EndDate}
                    selected={data.end_date}
                    className={`form-control ${
                        !data.isApplicable && !!data.validation && !data.end_date
                            ? 'blank-field'
                            : ''
                    }`}
                    maxDate={new Date()}
                    minDate={
                        data.start_date ? data.start_date + 86400000 : new Date() - 94608000000
                    }
                    dateFormat="MM/dd/yyyy"
                    onChange={(date) => this.handleDate(index, 'end_date', date)}
                    showMonthDropdown
                    showYearDropdown
                    disabled={!data.start_date || isDisabled || !!data.isApplicable}
                    dropdownMode="select"
                    locale={this.props?.langData?.language || 'en'}
                />
            </div>
        );
    };

    /**
     * @description This function shows image preview.
     * @param quesIndex It consists index of question whose against the image is uploaded.
     * @param imgObj It consists data of image file
     * @param imgIndex It consists index of image to identify which image is selected out of multiple images of same question.
     */
    showHideImageModel = (quesIndex, imgObj, imgIndex) => {
        let { showImageModel } = this.state;
        this.setState({
            showImageModel: !showImageModel,
            quesIndex,
            imgObj,
            imgIndex
        });
    };

    /**
     * @description This function deletes the particular selected image of the question.
     */
    deleteImage = async () => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        let { taskInfo, QuesList, imgObj, quesIndex, imgIndex } = this.state;
        let { task_id } = taskInfo;
        const data = {
            task_id,
            img_id: imgObj.img_id
        };

        this.showHideImageModel();
        spinnerService.show('Oval', lang.STRINGS.DeleteImage);
        try {
            const config = {
                headers: {
                    authorization: `Bearer ${getData('token')}`,
                    language: language
                }
            };
            let response = await Axios.post(CONFIG.deleteTaskImages, data, config);
            spinnerService.hide('Oval');
            if (response && response.data && response.data.responseCode == 200) {
                QuesList[quesIndex]['url'].splice(imgIndex, 1);
                this.setState({ QuesList });
            }
            if (response && response.data && response.data.responseCode == 401) {
                logoutUnauthorizedUser(this, response.data.message);
            } else {
                AlertModal.showAlert(response.data.message);
            }
        } catch (error) {
            spinnerService.hide('Oval');
            AlertModal.showAlert(error.message);
        }
    };

    /**
     * @description This function verifies that all the filled details saved or not and confirms
     * with the reviewer that he/she is sure to assign task back to auditor.
     */
    checkAssignBack = () => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let { checkDataChange } = this.state;
        if (checkDataChange) {
            AlertModal.showAlert(lang.STRINGS.SaveBeforeAssign);
        } else {
            AlertModal.showAlert(
                lang.STRINGS.ConfirmReassign,
                'assignBack',
                'toggleReasonModal',
                this
            );
        }
    };

    /**
     * @description This function opens modal to enter reason for assigning task back to auditor.
     */
    toggleReasonModal = () => {
        let { openReasonModal } = this.state;
        this.setState({
            openReasonModal: !openReasonModal
        });
    };

    /**
     * @description This function updates the tasks status of the particular task to be
     * In-Progress after task is assigned back to the auditor by reviewer and mails the reason
     * to the auditor.
     */
    assignTaskBack = (reason) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        this.toggleReasonModal();
        let { taskInfo } = this.state;
        let { task_id } = taskInfo;
        const dataObj = {
            task_id: task_id,
            reason
        };
        spinnerService.show('Oval', lang.STRINGS.AssigningBack);
        CallApi('POST', CONFIG.assignBack, {}, dataObj, '', 'assignBack', this, language);
    };

    /**
     * @description assignback API callback function
     * @param response This is the response structure received from API
     */
    assignBack = (response) => {
        AlertModal.showAlert(response.message);
        if (response.success) {
            setTimeout(() => {
                AlertModal.hideAlert();
                this.props.history.goBack();
            }, 2000);
        }
    };

    /**
     * @description This function open and closes add question modal in case of machine health.
     */
    showHideAddQuesModel = (quesTypeName) => {
        let { showAddQuesModel } = this.state;
        this.setState({ showAddQuesModel: !showAddQuesModel });
        this.setState({ quesTypeName: quesTypeName });
    };

    /**
     * @description This function submits added question data into the list of questions of machine health.
     * @param data It consists added question details.
     */
    addQues = async (data) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        this.showHideAddQuesModel();
        let { taskInfo } = this.state;
        let { task_id, task_name, audit_id } = taskInfo;

        let obj = {
            ...data,
            task_id,
            audit_id,
            asset_type_id: getAuditId(task_name),
            type: this.state.quesTypeName
        };

        let response = await serverCalls(
            CONFIG.addMHTaskQuesManual,
            obj,
            lang.STRINGS.ADDINGQUESTION,
            this,
            language
        );

        if (response) {
            this.props.getQuestionData(true);
        }
    };

    /**
     * @description This function handles the task's confirmation for declaration.
     */
    handleCheckBox = () => {
        let { confirmation } = this.state;
        this.setState({
            confirmation: !confirmation
        });
    };

    isInspected = (index, value) => {
        let { QuesList } = this.state;
        QuesList[index]['isInspected'] = value;
        this.setState({ QuesList, checkDataChange: true });
    };

    isApplicable = (index, value) => {
        let { QuesList } = this.state;
        QuesList[index]['isApplicable'] = value;
        this.setState({ QuesList, checkDataChange: true });
    };

    isRepeatedFinding = (index, value) => {
        let { QuesList } = this.state;
        QuesList[index]['isRepeatedFinding'] = value;
        this.setState({ QuesList, checkDataChange: true });
    };

    render() {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let {
            taskInfo,
            currentTabName,
            heading,
            QuesList,
            isModalOpen,
            imgObj,
            isQuantifiableModalOpen,
            questionData,
            PMQualityOptions,
            DVOptions,
            isSubmitClicked,
            selectedQuestionId,
            showImageModel,
            openReasonModal,
            showAddQuesModel,
            confirmation,
            index,
            currentQuesIndex,
            selectedType,
            isUploadFile,
            uploadObj,
            openCostEvaluateModal,
            isMH_QuesModalOpen
        } = this.state;
        let { task_status } = taskInfo ? taskInfo : {};

        let { userData } = getData('loginData');
        let isDisabled =
            (userData.role_name === 'auditor' && task_status == 2) || task_status == 3
                ? true
                : false;
        return (
            <div className="work-order-wrap">
                <IdleTimerComponent onSave={() => this.submitAns('idle')} />

                {currentTabName === 'Machine Health' && (
                    <div className="filter-container barcode-scanner">
                        <div></div>
                        <div className="form-group">
                            <button
                                type="button"
                                className="btn-blue"
                                onClick={() => this.showHideAddQuesModel(currentTabName)}>
                                {lang.STRINGS.AddMHAuditPoint}
                            </button>
                        </div>
                    </div>
                )}

                {(this.props.task_name == 'Facilities' || this.props.task_name == 'Utilities') && (
                    <div className="filter-container barcode-scanner">
                        <div></div>
                        <div className="form-group">
                            <button
                                type="button"
                                className="btn-blue"
                                onClick={() => this.showHideAddQuesModel(currentTabName)}>
                                {(currentTabName === 'General Utilities' ||
                                    currentTabName === 'General Facilities') &&
                                    lang.STRINGS.AddGeneralPoint}

                                {currentTabName === 'Preventative Maintenance' &&
                                    lang.STRINGS.AddPMPoint}
                            </button>
                        </div>
                    </div>
                )}

                <div className="headiong-outer">
                    <h4>{lang.STRINGS.AuditTasksQuestions}</h4>
                </div>
                <div className="work-order-tabs-outer">
                    <div className="work-order-tabs">
                        {heading.map((data, index) => (
                            <button
                                key={index}
                                type="button"
                                className={`btn-wrk-ordr  tablinks
                ${currentTabName == data ? 'active' : ''}`}
                                onClick={() => this.tabChange(data)}>
                                {data}
                            </button>
                        ))}
                    </div>
                    <select className="tab-select" onClick={(e) => this.tabChangeName(e)}>
                        {heading.map((data, index) => (
                            <option key={index} value={data}>
                                {data}
                            </option>
                        ))}
                    </select>
                    <div className="table-outer tabcontent active">
                        {QuesList.map((data, index) => {
                            if (data.long_heading == currentTabName) {
                                return (
                                    <div key={index}>
                                        {currentTabName === 'General Utilities' ||
                                        currentTabName === 'General Facilities' ? (
                                            (data.auditor_flag != 3 ||
                                                task_status == 2 ||
                                                task_status == 3) && (
                                                // <div className={`data-upload-row ${!data.reviewer_remark ?
                                                //   ((!!data.isApplicable || (data.url.length != 0 && task_status != 2 && data.auditor_flag === 1) ||
                                                //     (data.auditor_flag === 2 && task_status != 2 && !!data.validation && data.validation == "Pass") ||
                                                //     (data.auditor_flag === 2 && task_status != 2 && !!data.validation && data.validation != "Pass" && !!data.observation && !!data.auditor_comments) ||
                                                //     (task_status === 2 && !!data.validation && (data.validation == "Pass")) || (task_status == 2 &&
                                                //       !!data.validation && data.validation != "Pass" && (data.auditor_flag !=1 || (data.auditor_flag == 1 && data.url.length !=0)) && !!data.observation && !!data.auditor_comments &&
                                                //       !!data.reviewer_comments)) ? 'completed-info' : 'missing-info') : 'reviewer-remark'}`}>
                                                <div className="data-upload-row">
                                                    {data?.short_ques && (
                                                        <div className="data-upload-col">
                                                            {data.short_ques}
                                                            <br />
                                                            {(currentTabName ===
                                                                'Preventative Maintenance' ||
                                                                currentTabName ===
                                                                    'Machine Health') &&
                                                                data?.operation_numb}
                                                        </div>
                                                    )}
                                                    <div className="data-upload-col">
                                                        {data.long_ques}
                                                    </div>
                                                    <div className="data-upload-col">
                                                        {this.getSelectBox(index, data, 3)}
                                                    </div>
                                                    {/* {(task_status == 2) &&
                          <div className="data-upload-col">
                            <select className="form-control" id={index} name="validation"
                              value={data.validation ? data.validation : ""} onChange={this.handleChange}
                              disabled={isDisabled || !!data.isApplicable}>
                              <option disabled hidden value="">Select Option</option>
                              <option value="Major NC">Major NC</option>
                              <option value="Minor NC">Minor NC</option>
                              <option value="Pass">Pass</option>
                            </select>
                          </div>
                        } */}
                                                    {data.auditor_flag == 1 && (
                                                        <div className="data-upload-col">
                                                            {
                                                                <div className="upload-file-control">
                                                                    <input
                                                                        type="file"
                                                                        id={data.ques_id}
                                                                        name={index}
                                                                        onChange={(event) =>
                                                                            this.openUploadModal(
                                                                                event,
                                                                                data,
                                                                                index
                                                                            )
                                                                        }
                                                                        disabled={
                                                                            isDisabled ||
                                                                            !!data.isApplicable
                                                                        }
                                                                    />

                                                                    <label
                                                                        className={`form-control ${
                                                                            !data.isApplicable &&
                                                                            ((data.auditor_flag ===
                                                                                2 &&
                                                                                !!data.validation &&
                                                                                data.validation !==
                                                                                    'Pass' &&
                                                                                !data.url.length) ||
                                                                                (task_status == 2 &&
                                                                                    !!data.validation &&
                                                                                    data.validation !==
                                                                                        'Pass' &&
                                                                                    !data.url
                                                                                        .length) ||
                                                                                (!!isSubmitClicked &&
                                                                                    task_status !=
                                                                                        2 &&
                                                                                    data.auditor_flag ==
                                                                                        1 &&
                                                                                    !data.url
                                                                                        .length))
                                                                                ? data.long_heading !==
                                                                                  'Diagnostic Visit'
                                                                                    ? 'blank-field'
                                                                                    : ''
                                                                                : ''
                                                                        }`}
                                                                        htmlFor={data.ques_id}
                                                                        onClick={(event) =>
                                                                            this.openUploadModal(
                                                                                event,
                                                                                data,
                                                                                index
                                                                            )
                                                                        }>
                                                                        {lang.LABLES.UploadFile}
                                                                    </label>
                                                                </div>
                                                            }
                                                            <div className="group-upload-images margin-bottom-10">
                                                                {data.url &&
                                                                    data.url.map(
                                                                        (imgObj, imgIndex) =>
                                                                            imgObj.img_path &&
                                                                            (!imgObj.isVideo ? (
                                                                                <img
                                                                                    key={imgIndex}
                                                                                    src={
                                                                                        imgObj.img_path
                                                                                    }
                                                                                    alt="Images Icons"
                                                                                    className="upload-images"
                                                                                    onClick={() => {
                                                                                        if (
                                                                                            task_status !=
                                                                                            3
                                                                                        ) {
                                                                                            this.showHideImageModel(
                                                                                                index,
                                                                                                imgObj,
                                                                                                imgIndex
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <div
                                                                                    className="upload-images"
                                                                                    onClick={() => {
                                                                                        if (
                                                                                            task_status !=
                                                                                            3
                                                                                        ) {
                                                                                            this.showHideImageModel(
                                                                                                index,
                                                                                                imgObj,
                                                                                                imgIndex
                                                                                            );
                                                                                        }
                                                                                    }}>
                                                                                    <Thumbnail
                                                                                        key={
                                                                                            imgIndex
                                                                                        }
                                                                                        videoUrl={
                                                                                            imgObj.img_path
                                                                                        }
                                                                                        width={38}
                                                                                        height={38}
                                                                                    />
                                                                                </div>
                                                                            ))
                                                                    )}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {data.auditor_flag == 2 && task_status == 1 && (
                                                        <select
                                                            className="form-control"
                                                            id={index}
                                                            name="validation"
                                                            value={
                                                                data.validation
                                                                    ? data.validation
                                                                    : ''
                                                            }
                                                            onChange={this.handleChange}
                                                            disabled={
                                                                isDisabled || !!data.isApplicable
                                                            }>
                                                            <option selected hidden value="">
                                                                {lang.LABLES.SelectOption}
                                                            </option>
                                                            <option value="Pass">
                                                                {lang.LABLES.Yes}
                                                            </option>
                                                            <option value="Major NC">
                                                                {lang.LABLES.No}
                                                            </option>
                                                        </select>
                                                    )}

                                                    {showObservation(currentTabName) && (
                                                        <div className="data-upload-col">
                                                            <textarea
                                                                className={`form-control ${
                                                                    !data.isApplicable &&
                                                                    !!isSubmitClicked &&
                                                                    ((data.auditor_flag === 2 &&
                                                                        !!data.validation &&
                                                                        data.validation !==
                                                                            'Pass' &&
                                                                        !data.observation) ||
                                                                        (task_status == 2 &&
                                                                            !!data.validation &&
                                                                            data.validation !=
                                                                                'Pass' &&
                                                                            !data.observation))
                                                                        ? data.long_heading !==
                                                                          'Diagnostic Visit'
                                                                            ? !!data.isApplicable &&
                                                                              !!isSubmitClicked &&
                                                                              !data.observation
                                                                                ? 'blank-field'
                                                                                : ''
                                                                            : ''
                                                                        : ''
                                                                }`}
                                                                value={
                                                                    data.observation
                                                                        ? data.observation
                                                                        : ''
                                                                }
                                                                placeholder={
                                                                    lang.LABLES.Observation
                                                                }
                                                                id={index}
                                                                name="observation"
                                                                onChange={
                                                                    this.handleChangeWithLength
                                                                }
                                                                disabled={isDisabled}></textarea>
                                                        </div>
                                                    )}
                                                    <div className="data-upload-col">
                                                        <textarea
                                                            className={`form-control ${
                                                                !data.isApplicable &&
                                                                ((data.auditor_flag === 2 &&
                                                                    !!data.validation &&
                                                                    data.validation !== 'Pass' &&
                                                                    !data.auditor_comments) ||
                                                                    (task_status == 2 &&
                                                                        !!data.validation &&
                                                                        data.validation != 'Pass' &&
                                                                        !data.auditor_comments))
                                                                    ? data.long_heading !==
                                                                      'Diagnostic Visit'
                                                                        ? 'blank-field'
                                                                        : data.validation ===
                                                                          'Immediate Attention Required by Plant'
                                                                        ? 'blank-field'
                                                                        : ''
                                                                    : ''
                                                            }`}
                                                            value={
                                                                data.auditor_comments
                                                                    ? data.auditor_comments
                                                                    : ''
                                                            }
                                                            id={index}
                                                            name="auditor_comments"
                                                            onChange={this.handleChangeWithLength}
                                                            disabled={isDisabled}
                                                            placeholder={
                                                                data.long_heading !==
                                                                'Diagnostic Visit'
                                                                    ? lang.LABLES.Impact
                                                                    : lang.LABLES.COMMENT
                                                            }></textarea>
                                                    </div>
                                                    <div className="data-upload-col">
                                                        <textarea
                                                            className={`form-control ${
                                                                !data.isApplicable &&
                                                                task_status == 2 &&
                                                                !!data.validation &&
                                                                data.validation != 'Pass' &&
                                                                !data.reviewer_comments
                                                                    ? data.long_heading !==
                                                                      'Diagnostic Visit'
                                                                        ? 'blank-field'
                                                                        : ''
                                                                    : ''
                                                            }`}
                                                            value={
                                                                data.reviewer_comments
                                                                    ? data.reviewer_comments
                                                                    : ''
                                                            }
                                                            id={index}
                                                            name="reviewer_comments"
                                                            onChange={this.handleChangeWithLength}
                                                            disabled={isDisabled}
                                                            placeholder={
                                                                lang.LABLES.Recommendation
                                                            }></textarea>
                                                    </div>
                                                    {(task_status == 2 ||
                                                        ((task_status == 1 || task_status == 3) &&
                                                            data.reviewer_remark)) && (
                                                        <div className="data-upload-col">
                                                            <textarea
                                                                className="form-control"
                                                                value={
                                                                    data.reviewer_remark
                                                                        ? data.reviewer_remark
                                                                        : ''
                                                                }
                                                                id={index}
                                                                name="reviewer_remark"
                                                                onChange={
                                                                    this.handleChangeWithLength
                                                                }
                                                                disabled={
                                                                    isDisabled || task_status == 1
                                                                }
                                                                placeholder={
                                                                    lang.LABLES.ReviewerComment
                                                                }></textarea>
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        ) : (
                                            // <div className={`data-upload-row ${(!!data.validation ?
                                            //   (!data.reviewer_remark) ? ((!data.isInspected && !data.isApplicable) ? ((data.isQuant === 'false' || currentTabName === "PM Quality Assessment") ?
                                            //   ((currentTabName === "PM Quality Assessment" && data.long_ques === "PM Cart Equipped with Tools Required for PM/Repair") ? (data.validation != 'Pass' && (!data.auditor_comments ||
                                            //     !data.observation || !data.url || data.url.length == 0 || (task_status == 2 && !data.reviewer_comments)) ? ((!!data.isInspected || !!data.isApplicable) ?
                                            //     'completed-info' : 'missing-info') : 'completed-info') : ((data.validation != 'Pass' && data.isQuant == "false" &&
                                            //     (!data.auditor_comments || !data.observation || !data.url || data.url.length == 0 || (task_status == 2 && !data.reviewer_comments))) ? 'missing-info' : 'completed-info')) :
                                            //     (currentTabName === "General Audit" && !!data.start_date && !!data.end_date) ? 'completed-info' : ((data.isQuant === "true" && currentTabName !== "General Audit") ?
                                            //       ((task_status == 2 && currentTabName === "Machine Health" && !(parseFloat(data.validation) >= parseFloat(data.min) && parseFloat(data.validation) <= parseFloat(data.max)) &&
                                            //       (!data.observation || !data.auditor_comments || !data.reviewer_comments)) ? 'missing-info' : 'completed-info') : ((!!data.isInspected || !!data.isApplicable) ?
                                            //       'completed-info' : 'missing-info'))) : 'completed-info') : 'reviewer-remark' : ((!!data.isInspected || !!data.isApplicable) ? 'completed-info' : 'missing-info'))}`}>
                                            <div className="data-upload-row">
                                                <div className="data-upload-col">
                                                    {data.short_ques}
                                                    <br />
                                                    {(currentTabName ===
                                                        'Preventative Maintenance' ||
                                                        currentTabName === 'Machine Health') &&
                                                        data?.operation_numb}
                                                    <br />
                                                    {(task_status == 2 || task_status == 3) &&
                                                        currentTabName === 'Machine Health' &&
                                                        data.isQuant === 'true' && (
                                                            <span>
                                                                {lang.LABLES.MIN}: {data?.min}{' '}
                                                                {lang.LABLES.MAX}: {data?.max}
                                                            </span>
                                                        )}
                                                </div>
                                                <div className="data-upload-col">
                                                    {data.long_ques}
                                                </div>

                                                {data.mh_script === 'YES' ? (
                                                    this.MachineHealth_Ques(data, index)
                                                ) : (APPLICABLE_TABS.indexOf(currentTabName) > -1 &&
                                                      data.isQuant === 'false') ||
                                                  currentTabName === 'Preventative Maintenance' ? (
                                                    this.AdditionalQuesComponent(data, index)
                                                ) : (
                                                    <div className="data-upload-col">
                                                        {currentTabName ===
                                                            'PM Quality Assessment' &&
                                                        data.long_ques ===
                                                            'Standard PM Resources employed during the Assessment'
                                                            ? this.getSelectBox(index, data, 2)
                                                            : this.getInput(index, data)}
                                                    </div>
                                                )}
                                                {taskInfo.task_name ==
                                                    'Line Equipment and Injection' &&
                                                    currentTabName == 'General Audit' &&
                                                    (task_status || isDisabled) &&
                                                    this.getDateSelector(index, data)}
                                                {/* {(taskInfo.task_name == "Line Equipment and Injection" && currentTabName == "General Audit" &&
                          (task_status == 2 || isDisabled)) &&
                          <span className='static-date'>
                            <label>Start Date: </label><span> {formatDate(data.start_date)}</span><br />
                            <label>End Date: </label><span> {formatDate(data.end_date)}</span>
                          </span>
                        } */}
                                                {!(
                                                    taskInfo.task_name ==
                                                        'Line Equipment and Injection' &&
                                                    currentTabName == 'General Audit'
                                                ) && (
                                                    <div className="data-upload-col">
                                                        <div className="upload-file-control">
                                                            <input
                                                                type="file"
                                                                id={data.ques_id}
                                                                name={index}
                                                                onChange={(event) =>
                                                                    this.openUploadModal(
                                                                        event,
                                                                        data,
                                                                        index
                                                                    )
                                                                }
                                                                disabled={isDisabled}
                                                            />
                                                            {this.state.currentTabName !==
                                                                'Heights' && (
                                                                <label
                                                                    className={`form-control ${
                                                                        !data.isApplicable &&
                                                                        !!data.validation &&
                                                                        data.validation !==
                                                                            'Pass' &&
                                                                        (data.isQuant === 'false' ||
                                                                            data.long_ques ===
                                                                                'Standard PM Resources employed during the Assessment') &&
                                                                        !data.url.length
                                                                            ? data.long_heading !==
                                                                              'Diagnostic Visit'
                                                                                ? 'upload-blank-field'
                                                                                : ''
                                                                            : !!data.isApplicable &&
                                                                              !data.url.length
                                                                            ? data.long_heading !==
                                                                              'Diagnostic Visit'
                                                                                ? 'upload-blank-field'
                                                                                : ''
                                                                            : ''
                                                                    }`}
                                                                    htmlFor={data.ques_id}
                                                                    onClick={(event) =>
                                                                        this.openUploadModal(
                                                                            event,
                                                                            data,
                                                                            index
                                                                        )
                                                                    }>
                                                                    {lang.LABLES.UploadFile}
                                                                </label>
                                                            )}
                                                        </div>
                                                        <div className="group-upload-images margin-bottom-10">
                                                            {data.url &&
                                                                data.url.map(
                                                                    (imgObj, imgIndex) =>
                                                                        imgObj.img_path &&
                                                                        (!imgObj.isVideo ? (
                                                                            <img
                                                                                key={imgIndex}
                                                                                src={
                                                                                    imgObj.img_path
                                                                                }
                                                                                alt="Images Icons"
                                                                                className="upload-images"
                                                                                onClick={() => {
                                                                                    if (
                                                                                        task_status !=
                                                                                        3
                                                                                    ) {
                                                                                        this.showHideImageModel(
                                                                                            index,
                                                                                            imgObj,
                                                                                            imgIndex
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <div
                                                                                className="upload-images"
                                                                                onClick={() => {
                                                                                    if (
                                                                                        task_status !=
                                                                                        3
                                                                                    ) {
                                                                                        this.showHideImageModel(
                                                                                            index,
                                                                                            imgObj,
                                                                                            imgIndex
                                                                                        );
                                                                                    }
                                                                                }}>
                                                                                <Thumbnail
                                                                                    key={imgIndex}
                                                                                    videoUrl={
                                                                                        imgObj.img_path
                                                                                    }
                                                                                    width={38}
                                                                                    height={38}
                                                                                />
                                                                            </div>
                                                                        ))
                                                                )}
                                                        </div>
                                                    </div>
                                                )}
                                                {showObservation(currentTabName) && (
                                                    <div className="data-upload-col">
                                                        <textarea
                                                            className={`form-control ${
                                                                (!data.isApplicable &&
                                                                    !!data.validation &&
                                                                    data.validation !== 'Pass' &&
                                                                    data.long_ques !==
                                                                        'Standard PM Resources employed during the Assessment' &&
                                                                    (data.isQuant == 'false' ||
                                                                        (task_status == 2 &&
                                                                            data.long_heading ==
                                                                                'Machine Health' &&
                                                                            data.isQuant ==
                                                                                'true' &&
                                                                            !(
                                                                                parseFloat(
                                                                                    data.validation
                                                                                ) >=
                                                                                    parseFloat(
                                                                                        data.min
                                                                                    ) &&
                                                                                parseFloat(
                                                                                    data.validation
                                                                                ) <=
                                                                                    parseFloat(
                                                                                        data.max
                                                                                    )
                                                                            ))) &&
                                                                    !data.observation) ||
                                                                ((data.observation === '' ||
                                                                    !data.observation) &&
                                                                    data.validation !== 'Pass' &&
                                                                    data.long_heading ===
                                                                        'PM Quality Assessment')
                                                                    ? data.long_heading !==
                                                                      'Diagnostic Visit'
                                                                        ? !data.observation
                                                                            ? 'blank-field'
                                                                            : ''
                                                                        : data.validation ===
                                                                          'Immediate Attention Required by Plant'
                                                                        ? 'blank-field'
                                                                        : ''
                                                                    : ''
                                                            }`}
                                                            value={
                                                                data.observation
                                                                    ? data.observation
                                                                    : ''
                                                            }
                                                            placeholder={lang.LABLES.Observation}
                                                            id={index}
                                                            name="observation"
                                                            onChange={this.handleChangeWithLength}
                                                            disabled={isDisabled}></textarea>
                                                    </div>
                                                )}

                                                <div className="data-upload-col">
                                                    <textarea
                                                        className={`form-control ${
                                                            (!data.isApplicable &&
                                                                !!data.validation &&
                                                                data.validation !== 'Pass' &&
                                                                (data.isQuant == 'false' ||
                                                                    (task_status == 2 &&
                                                                        data.long_heading ==
                                                                            'Machine Health' &&
                                                                        data.isQuant == 'true' &&
                                                                        !(
                                                                            parseFloat(
                                                                                data.validation
                                                                            ) >=
                                                                                parseFloat(
                                                                                    data.min
                                                                                ) &&
                                                                            parseFloat(
                                                                                data.validation
                                                                            ) <=
                                                                                parseFloat(data.max)
                                                                        ))) &&
                                                                !data.auditor_comments) ||
                                                            ((data.auditor_comments === '' ||
                                                                !data.auditor_comments) &&
                                                                data.validation !== 'Pass' &&
                                                                data.long_heading ===
                                                                    'PM Quality Assessment')
                                                                ? data.long_heading !==
                                                                  'Diagnostic Visit'
                                                                    ? 'blank-field'
                                                                    : data.validation ===
                                                                      'Immediate Attention Required by Plant'
                                                                    ? 'blank-field'
                                                                    : ''
                                                                : ''
                                                        }`}
                                                        value={
                                                            data.auditor_comments
                                                                ? data.auditor_comments
                                                                : ''
                                                        }
                                                        id={index}
                                                        name="auditor_comments"
                                                        onChange={this.handleChangeWithLength}
                                                        disabled={isDisabled || !!data.isApplicable}
                                                        placeholder={
                                                            data.long_heading !== 'Diagnostic Visit'
                                                                ? lang.LABLES.Impact
                                                                : lang.LABLES.COMMENT
                                                        }></textarea>
                                                </div>
                                                {data.long_heading !== 'Diagnostic Visit' && (
                                                    <div className="data-upload-col">
                                                        <textarea
                                                            className={`form-control ${
                                                                (task_status == 2 &&
                                                                    !data.isApplicable &&
                                                                    !!data.validation &&
                                                                    data.validation !== 'Pass' &&
                                                                    (data.isQuant == 'false' ||
                                                                        (task_status == 2 &&
                                                                            data.long_heading ==
                                                                                'Machine Health' &&
                                                                            data.isQuant ==
                                                                                'true' &&
                                                                            !(
                                                                                parseFloat(
                                                                                    data.validation
                                                                                ) >=
                                                                                    parseFloat(
                                                                                        data.min
                                                                                    ) &&
                                                                                parseFloat(
                                                                                    data.validation
                                                                                ) <=
                                                                                    parseFloat(
                                                                                        data.max
                                                                                    )
                                                                            ))) &&
                                                                    !data.reviewer_comments) ||
                                                                ((data.reviewer_comments === '' ||
                                                                    !data.reviewer_comments) &&
                                                                    data.validation !== 'Pass' &&
                                                                    data.long_heading ===
                                                                        'PM Quality Assessment')
                                                                    ? task_status === 2
                                                                        ? data.long_heading !==
                                                                          'Diagnostic Visit'
                                                                            ? 'blank-field'
                                                                            : ''
                                                                        : ''
                                                                    : ''
                                                            }`}
                                                            value={
                                                                data.reviewer_comments
                                                                    ? data.reviewer_comments
                                                                    : ''
                                                            }
                                                            id={index}
                                                            name="reviewer_comments"
                                                            onChange={this.handleChangeWithLength}
                                                            disabled={
                                                                isDisabled || !!data.isApplicable
                                                            }
                                                            placeholder={
                                                                lang.LABLES.Recommendation
                                                            }></textarea>
                                                    </div>
                                                )}
                                                {(task_status == 2 ||
                                                    ((task_status == 1 || task_status == 3) &&
                                                        data.reviewer_remark)) && (
                                                    <div className="data-upload-col">
                                                        <textarea
                                                            className="form-control"
                                                            value={
                                                                data.reviewer_remark
                                                                    ? data.reviewer_remark
                                                                    : ''
                                                            }
                                                            id={index}
                                                            name="reviewer_remark"
                                                            onChange={this.handleChangeWithLength}
                                                            disabled={
                                                                isDisabled || task_status == 1
                                                            }
                                                            placeholder={
                                                                lang.LABLES.ReviewerComment
                                                            }></textarea>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                );
                            }
                        })}
                    </div>
                </div>
                <div>
                    <input
                        type="checkbox"
                        name="confirmation"
                        value={confirmation}
                        onChange={this.handleCheckBox}
                    />
                    <label htmlFor="confirmation"> {lang.STRINGS.CONFIRMATIONMESSAGE}</label>
                </div>
                <div className="submition-outer">
                    {getData('loggedUser') === taskInfo.reviewer_name && task_status == 2 && (
                        <button className="btn-prmry" onClick={this.checkAssignBack}>
                            {lang.BUTTONS.AssignBack}
                        </button>
                    )}
                    {task_status != 3 && (
                        <>
                            <button
                                className="btn-prmry"
                                onClick={() => {
                                    this.submitAns('save');
                                }}>
                                {lang.BUTTONS.SavePreview}
                            </button>
                            <button
                                className="btn-prmry"
                                onClick={() => {
                                    this.submitAns('submit');
                                }}>
                                {lang.BUTTONS.Submit}
                            </button>
                        </>
                    )}
                </div>
                {isModalOpen ? (
                    <AdditionalQuestionModal
                        isModalOpen={isModalOpen}
                        isApplicable={this.isApplicable}
                        isInspected={this.isInspected}
                        isRepeatedFinding={this.isRepeatedFinding}
                        tabName={currentTabName}
                        parentQuestionId={selectedQuestionId}
                        index={currentQuesIndex}
                        questionList={{ ...QuesList[this.state.currentQuesIndex] }}
                        onCancel={this._oncloseModal}
                        taskInfo={taskInfo}
                        langData={this.props?.langData}
                    />
                ) : null}
                {openCostEvaluateModal ? (
                    <CostEvaluateModal
                        isModalOpen={openCostEvaluateModal}
                        isApplicable={this.isApplicable}
                        isInspected={this.isInspected}
                        tabName={currentTabName}
                        parentQuestionId={selectedQuestionId}
                        index={currentQuesIndex}
                        showCancel={true}
                        onCancel={(evt) => {
                            this.closeCostEvaluateModal(evt);
                        }}
                        taskInfo={taskInfo}
                        audit_id={this.state.taskInfo.audit_id}
                        langData={this.props?.langData}
                    />
                ) : null}
                {isMH_QuesModalOpen ? (
                    <MH_QuesModal
                        isModalOpen={isMH_QuesModalOpen}
                        quesData={questionData}
                        isApplicable={this.isApplicable}
                        index={index}
                        isInspected={this.isInspected}
                        isRepeatedFinding={this.isRepeatedFinding}
                        onCancel={this.closeMH_QuesModal}
                        langData={this.props?.langData}
                        taskInfo={taskInfo}
                        handleChange={this.handleChange}
                    />
                ) : null}
                {showImageModel && (
                    <ImagePrevew
                        isModalOpen={showImageModel}
                        imgObj={imgObj}
                        showDeleteButton={true}
                        onCancel={this.showHideImageModel}
                        deleteImage={this.deleteImage}
                        langData={this.props?.langData}
                    />
                )}
                {openReasonModal && (
                    <ReasonModal
                        isModalOpen={openReasonModal}
                        assignBack={this.assignTaskBack}
                        onCancel={this.toggleReasonModal}
                        langData={this.props?.langData}
                    />
                )}
                {showAddQuesModel && (
                    <AddQuesModal
                        isModalOpen={showAddQuesModel}
                        addQues={this.addQues}
                        onCancel={this.showHideAddQuesModel}
                        langData={this.props?.langData}
                        taskInfo={taskInfo}
                    />
                )}
                {isQuantifiableModalOpen && this.state.currentTabName !== 'Heights' && (
                    <QuantifiableModalDiagnostic
                        isQuantifiableModalOpen={isQuantifiableModalOpen}
                        quesData={questionData}
                        index={index}
                        restrictNumber={this.restrictToNumber}
                        onClose={this.closeQuantifiableModal}
                        isApplicable={this.isApplicable}
                        isRepeatedFinding={this.isRepeatedFinding}
                        handleChange={this.handleChange}
                        isInspected={this.isInspected}
                        PMQualityOptions={PMQualityOptions}
                        DVOptions={DVOptions}
                        selectedType={selectedType}
                        taskInfo={taskInfo}
                        langData={this.props?.langData}
                    />
                )}
                {isQuantifiableModalOpen && this.state.currentTabName === 'Heights' && (
                    <QuantifiableModal
                        isQuantifiableModalOpen={isQuantifiableModalOpen}
                        quesData={questionData}
                        index={index}
                        restrictNumber={this.restrictToNumber}
                        onClose={this.closeQuantifiableModal}
                        isApplicable={this.isApplicable}
                        handleChange={this.handleChange}
                        isInspected={this.isInspected}
                        PMQualityOptions={PMQualityOptions}
                        DVOptions={DVOptions}
                        selectedType={selectedType}
                        taskInfo={taskInfo}
                        langData={this.props?.langData}
                        questionsWithLimits={this.state.questions}
                        hasMeasurements={this.state.hasMeasurements}
                    />
                )}
                {isQuantifiableModalOpen && this.state.currentTabName === 'Diagnostic Visit' && (
                    <QuantifiableModalDiagnostic
                        isQuantifiableModalOpen={isQuantifiableModalOpen}
                        quesData={questionData}
                        index={index}
                        restrictNumber={this.restrictToNumber}
                        onClose={this.closeQuantifiableModal}
                        isApplicable={this.isApplicable}
                        handleChange={this.handleChange}
                        isInspected={this.isInspected}
                        PMQualityOptions={PMQualityOptions}
                        DVOptions={DVOptions}
                        selectedType={selectedType}
                        taskInfo={taskInfo}
                        langData={this.props?.langData}
                    />
                )}
                {isUploadFile && (
                    <ImageVideoSelectionModal
                        isModalOpen={isUploadFile}
                        onCancel={this.handleUploadModalClose}
                        handleFile={this.handleFile}
                        index={uploadObj.index}
                        data={uploadObj.data}
                        isSubmitClicked={isSubmitClicked}
                        langData={this.props?.langData}
                    />
                )}
            </div>
        );
    }
}

export default QuestionAnsBasedComponent;
