import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import * as CONSTANT from '../../shared/constants/data';
import { getData } from '../utils/storage.util';
import AlertModal from './AlertModal';
import LANGUAGECONST from '../constants/language';
import AuditWriteBack from '../../components/AuditDetailsComponent/AuditWriteBack';
import Axios from 'axios';
import CONFIG from '../../configuration';

const styles = {
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        border: '2px solid #006298',
        borderRadius: '5px',
        padding: '10px',
        backgroundColor: 'transparent',
        width: 'fit-content',
        margin: '12px 14px'
    },
    limitsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        border: '2px solid #006298',
        borderRadius: '5px',
        padding: '10px',
        backgroundColor: 'transparent',
        width: 'fit-content',
        margin: '12px 14px',
        marginLeft: '175px'
    },
    plusSign: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#006298'
    },
    buttonText: {
        fontSize: '14px',
        color: '#006298',
        marginLeft: '8px',
        display: 'flex',
        alignItems: 'center'
    },
    table: {
        width: '80%',
        margin: '0 auto',
        borderCollapse: 'collapse'
    },
    tableHeader: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        marginBottom: '2px',
        gap: '16px',
        maxWidth: '20%'
    },
    tableHeaderCell: {
        padding: '16px',
        textAlign: 'center'
    },

    tableRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: '10px',
        gap: '16px',
        marginLeft: '22px'
    },
    stationNumberCell: {
        backgroundColor: '#dcdcdc',
        textAlign: 'center',
        fontWeight: 'bold',
        padding: '8px',
        paddingLeft: '10px',
        paddingRight: '10px',
        boxSizing: 'border-box'
    },
    inputCell: {
        marginLeft: '0'
    },
    input: {
        width: '100%',
        padding: '4px',
        boxSizing: 'border-box',
        border: '1px solid #ddd',
        fontSize: '20px'
    },
    removeButtonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        border: '2px solid #006298',
        borderRadius: '5px',
        padding: '5px 10px',
        backgroundColor: 'transparent'
    },
    removeButtonText: {
        fontSize: '14px',
        color: '#006298',
        textAlign: 'center'
    },
    rightContainers: {
        marginRight: '10px',
        width: '50px',
        flexShrink: 0
    },
    outOfSpec: {
        marginRight: '10px',
        width: '50px',
        flexShrink: 0,
        fontSize: '12px',
        marginTop: '15px'
    }
};

class QuantifiableModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isInspected: 0,
            isApplicable: 0,
            isNotAccessed: null,
            isAuditEdit: false,
            applicableData: [],
            stations: [],
            nextStationNumber: 1, // Added nextStationNumber to state
            min: null,
            max: null,
            avg: null,
            mode: null,
            outOfSpecCount: 0,
            currentLimits: null
        };
    }

    saveStationsToDB = async () => {
        try {
            const { stations } = this.state;
            const invalidStations = stations.filter(
                (station) => station.measurement == null || station.measurement === ''
            );
            if (invalidStations.length > 0 || this.state.stations.length === 0) {
                this.props.hasMeasurements[this.props.quesData.short_ques] = true;
                AlertModal.showAlert(
                    LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS
                        .MeasurementsRequired
                );
                return;
            }

            const assetName = this.props.taskInfo.asset;
            const questions = this.props.quesData.short_ques;
            const taskId = this.props.taskInfo.task_id;
            const auditId = this.props.taskInfo.audit_id;
            const config = {
                headers: {
                    authorization: `Bearer ${getData('token')}`
                }
            };

            const response = await Axios.post(
                `${CONFIG.measurments}`,
                { stations, assetName, questions, taskId, auditId },
                config
            );

            if (response.status === 200) {
                AlertModal.showAlert('Stations saved successfully!');
                this.props.onClose();
            } else {
                console.error('Error saving stations:', response);
                AlertModal.showAlert('Error saving stations.');
            }
        } catch (error) {
            console.error('Error saving stations to database:', error);
            AlertModal.showAlert('An error occurred while saving.');
        }
    };

    fetchMeasurements = async () => {
        try {
            const assetName = this.props.taskInfo.asset;
            const questions = this.props.quesData.short_ques;
            const taskId = this.props.taskInfo.task_id;

            const config = {
                headers: {
                    authorization: `Bearer ${getData('token')}`
                },
                params: {
                    assetName: assetName,
                    questions: questions,
                    taskId: taskId
                }
            };

            const response = await Axios.get(`${CONFIG.getMeasurements}`, config);

            if (response.status === 200 && response.data.success) {
                const data = response.data.response;
                const maxStationNumber =
                    data.length > 0 ? Math.max(...data.map((s) => s.stationNumber)) : 0;
                this.setState({ stations: data, nextStationNumber: maxStationNumber + 1 }, () => {
                    this.calculateStatistics();
                });
            } else {
                console.error('Error fetching measurements:', response);
                AlertModal.showAlert('Error fetching measurements.');
            }
        } catch (error) {
            console.error('Error fetching measurements from database:', error);
            AlertModal.showAlert('An error occurred while fetching data.');
        }
    };

    componentDidMount() {
        this.fetchMeasurements();
        this.props.hasMeasurements[this.props.quesData.short_ques] = true;
        let { isApplicable } = this.state;
        if (!!this.props.quesData.isApplicable) {
            this.setState(
                {
                    isApplicable: isApplicable === 0 ? 1 : 0
                },
                () => {
                    let element = document.getElementById('btn-1');
                    if (element) {
                        element.classList.toggle('active');
                    }
                }
            );
        }

        // Limits for current question
        const { questionsWithLimits, quesData } = this.props;
        const currentQuestion = questionsWithLimits.find(
            (question) => question.description === quesData.short_ques
        );
        if (currentQuestion) {
            this.setState({
                currentLimits: {
                    min_limit: currentQuestion.min_limit,
                    max_limit: currentQuestion.max_limit
                }
            });
        }
    }

    componentDidUpdate(_, prevState) {
        if (prevState.stations !== this.state.stations) {
            localStorage.setItem('stations', JSON.stringify(this.state.stations));
            this.calculateStatistics();
        }
    }

    onSave(validation) {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];

        let { isInspected, isApplicable } = this.state;
        let { selectedType, quesData, taskInfo } = this.props;
        let { task_status } = taskInfo;
        if (
            !!validation ||
            !!isInspected ||
            !!isApplicable ||
            (selectedType === 3 && task_status == 1) ||
            (selectedType === 3 &&
                quesData.long_heading != 'General Utilities' &&
                quesData.long_heading != 'PARTS ROOM SAFETY & SECURITY')
        ) {
            this.props.onClose();
        } else {
            AlertModal.showAlert(lang.STRINGS.AllFieldsRequired);
        }
    }

    handleAddStation = () => {
        this.props.hasMeasurements[this.props.quesData.short_ques] = true;
        this.setState((prevState) => ({
            stations: [
                ...prevState.stations,
                { stationNumber: prevState.nextStationNumber, measurement: '' }
            ],
            nextStationNumber: prevState.nextStationNumber + 1
        }));
    };

    handleMeasurementChange = (index, event) => {
        const newStations = [...this.state.stations];
        newStations[index].measurement = event.target.value;
        this.setState({ stations: newStations });
    };

    handleRemoveStation = (index) => {
        if (this.state.stations.length === 0) {
            this.props.hasMeasurements[this.props.quesData.short_ques] = false;
        }
        const newStations = this.state.stations.filter((_, i) => i !== index);
        this.setState({ stations: newStations });
    };

    setIsAuditEdit = () => {
        this.setState({
            isAuditEdit: true
        });
    };

    toggleBtn = (id, index) => {
        let { taskInfo } = this.props;
        let { task_status } = taskInfo;
        let { isApplicable, isInspected } = this.state;
        if (task_status != 3) {
            if (id === 'btn-1') {
                this.setState(
                    {
                        isApplicable: isApplicable == 0 ? 1 : 0
                    },
                    () => {
                        this.setIsApplicable(id, index);
                    }
                );
            } else {
                this.setState(
                    {
                        isInspected: isInspected == 0 ? 1 : 0
                    },
                    () => {
                        this.setIsInspected(id, index);
                    }
                );
            }
        }
    };

    setIsInspected = (id, index) => {
        let { isInspected } = this.state;
        let element = document.getElementById(id).classList;
        element.toggle('active');
        this.props.isInspected(index, isInspected);
    };

    setIsApplicable = (id, index) => {
        let { isApplicable } = this.state;
        let element = document.getElementById(id).classList;

        element.toggle('active');

        this.props.isApplicable(index, isApplicable);
    };

    setApplicableData = (data) => {
        this.setState({
            applicableData: data
        });
    };
    calculateStatistics = () => {
        const measurements = this.state.stations
            .map((station) => parseFloat(station.measurement))
            .filter((value) => !isNaN(value));

        if (measurements.length > 0) {
            const min = Math.min(...measurements);
            const max = Math.max(...measurements);
            const avg = (measurements.reduce((a, b) => a + b, 0) / measurements.length).toFixed(2);
            const mode = this.calculateMode(measurements);

            this.setState({
                min,
                max,
                avg: parseFloat(avg),
                mode,
                outOfSpecCount: this.calculateOutOfSpecCount(measurements) || 0
            });
        } else {
            this.setState({
                min: null,
                max: null,
                avg: null,
                mode: null,
                outOfSpecCount: 0
            });
        }
    };

    calculateMode = (arr) => {
        const frequency = {};
        let maxFreq = 0;
        let mode;

        arr.forEach((value) => {
            frequency[value] = (frequency[value] || 0) + 1;
            if (frequency[value] > maxFreq) {
                maxFreq = frequency[value];
                mode = value;
            }
        });
        return mode;
    };

    calculateOutOfSpecCount = (measurements) => {
        const { currentLimits } = this.state;
        if (!currentLimits) {
            return 0; // No limits defined, so return 0
        }

        const { min_limit, max_limit } = currentLimits;
        let outOfSpecCount = 0;

        measurements.forEach((value) => {
            if (value < min_limit || value > max_limit) {
                outOfSpecCount += 1;
            }
        });

        return outOfSpecCount;
    };
    render() {
        const { measurementsByQuestion } = this.state;
        const { currentLimits } = this.state;
        let {
            index,
            quesData,
            isQuantifiableModalOpen,
            PMQualityOptions,
            DVOptions,
            selectedType,
            taskInfo
        } = this.props;

        let { task_status } = taskInfo ? taskInfo : {};
        let { userData } = getData('loginData');
        let isDisabled =
            (userData.role_name === 'auditor' && task_status == 2) || task_status == 3
                ? true
                : false;
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let {
            isApplicable,
            isAuditEdit,
            applicableData,
            stations,
            min,
            max,
            avg,
            mode,
            outOfSpecCount
        } = this.state;

        return (
            <Modal
                open={isQuantifiableModalOpen}
                onClose={this.props.onClose}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                center>
                <div className="pop-up-bg" id="create-audit-popup">
                    <div className="rqst-accs-popup">
                        <div className="create-audit-outer"></div>
                        <div className="toggle-block">
                            <div className="toggle-block-1"></div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={styles.buttonContainer} onClick={this.handleAddStation}>
                                <div style={styles.plusSign}>+</div>
                                <div style={styles.buttonText}>{lang.BUTTONS.AddStation}</div>
                            </div>
                            <div className="current-limits" style={styles.limitsContainer}>
                                <h4>Current Limits:</h4>
                                {currentLimits ? (
                                    <ul>
                                        <li>Min: {currentLimits.min_limit}</li>
                                        <li>Max: {currentLimits.max_limit}</li>
                                    </ul>
                                ) : (
                                    <p>No limits set</p>
                                )}
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                            }}>
                            <div>
                                <div style={styles.tableHeader}>
                                    <div style={styles.tableHeaderCell}>Station Number</div>
                                    <div style={styles.tableHeaderCell}>Measurements</div>
                                    <div style={{ ...styles.tableHeaderCell, marginLeft: '60px' }}>
                                        Actions
                                    </div>
                                </div>
                                {stations.map((station, index) => (
                                    <div key={index} style={styles.tableRow}>
                                        <div style={styles.stationNumberCell}>
                                            {station.stationNumber}
                                        </div>
                                        <div style={styles.inputCell}>
                                            <input
                                                type="text"
                                                value={station.measurement}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^-?\d*\.?\d*$/.test(value)) {
                                                        this.handleMeasurementChange(index, e);
                                                    }
                                                }}
                                                style={styles.input}
                                            />
                                        </div>
                                        <div
                                            style={styles.removeButtonContainer}
                                            onClick={() => this.handleRemoveStation(index)}>
                                            <div style={styles.removeButtonText}>
                                                {lang.BUTTONS.RemoveStation}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '10px',
                                    marginRight: '100px'
                                }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={styles.rightContainers}>Avg:</label>
                                    <input
                                        value={avg !== null ? avg : ''}
                                        disabled
                                        type="text"
                                        style={{ ...styles.input, flexGrow: 1, flexShrink: 0 }}
                                    />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={styles.rightContainers}>Min:</label>
                                    <input
                                        value={min !== null ? min : ''}
                                        disabled
                                        type="text"
                                        style={{ ...styles.input, flexGrow: 1, flexShrink: 0 }}
                                    />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={styles.rightContainers}>Max:</label>
                                    <input
                                        value={max !== null ? max : ''}
                                        disabled
                                        type="text"
                                        style={{ ...styles.input, flexGrow: 1, flexShrink: 0 }}
                                    />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={styles.rightContainers}>Mode:</label>
                                    <input
                                        value={mode !== null ? mode : ''}
                                        disabled
                                        type="text"
                                        style={{ ...styles.input, flexGrow: 1, flexShrink: 0 }}
                                    />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={styles.outOfSpec}>
                                        No. of stations out of Specifications
                                    </label>
                                    <input
                                        value={outOfSpecCount}
                                        disabled
                                        type="text"
                                        style={{ ...styles.input, flexGrow: 1, flexShrink: 0 }}
                                    />
                                </div>
                            </div>
                        </div>

                        {(!isApplicable || !!isApplicable) && (
                            <div className="crt-odi-btn-otr">
                                <button
                                    className="btn-prmry"
                                    type="button"
                                    onClick={() => this.saveStationsToDB()}>
                                    {lang.BUTTONS.Save}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
        );
    }
}

export default QuantifiableModal;
