import Axios from 'axios';
import CONFIG from '../../configuration';

import * as ACTION_TYPE from './common_action_types';
import { getData } from '../../shared/utils/storage.util';
import { spinnerService } from '../../shared/services/spinner.service';
import AlertModal from '../../shared/modals/AlertModal';
import { history } from '../configureStore';
import { logoutUnauthorizedUser } from '../../shared/utils/auth.util';
import LANGUAGECONST from '../../shared/constants/language';

export const getPostDataWithType =
    (url, data, type, loaderMsg, obj, language = 'en') =>
    async (dispatch) => {
        spinnerService.show('Oval', loaderMsg);
        try {
            const config = {
                headers: { authorization: `Bearer ${getData('token')}`, language: language }
            };
            let response = await Axios.post(url, data, config);
            spinnerService.hide('Oval');
            if (response && response.data && response.data.responseCode != 401) {
                if (response && response.data && response.data.responseCode == 200) {
                    dispatch({
                        type,
                        payload: response.data.response
                    });
                } else {
                    if (type == ACTION_TYPE.WO_QUES_DATA) {
                        dispatch({
                            type,
                            payload: []
                        });
                    }
                    AlertModal.showAlert(response.data.message);
                }
            } else {
                logoutUnauthorizedUser(obj, response.data.message);
            }
        } catch (error) {
            spinnerService.hide('Oval');
            AlertModal.showAlert(error.message);
        }
    };

export const getPostDataWithAction =
    (url, data, path, method = null, loaderMsg, obj, language = 'en') =>
    async (dispatch) => {
        let lang = LANGUAGECONST[language == 'es' ? 'ESP' : 'ENG'];
        // spinnerService.show('Oval', loaderMsg);
        if (path !== 'idle') spinnerService.show('Oval', loaderMsg);
        try {
            const config = {
                headers: { authorization: `Bearer ${getData('token')}`, language: language }
            };
            let response = await Axios.post(url, data, config);
            spinnerService.hide('Oval');
            if (response && response.data && response.data.responseCode != 401) {
                if (response && response.data && response.data.responseCode == 200) {
                    if (path) {
                        if (path == 'SAVE') {
                            return 1;
                        } else if (path == 'ADD TASK') {
                            method(response.data);
                        } else if (path == 'idle') {
                            return 1;
                            // AlertModal.showAlert(lang.STRINGS.DataSavedSuccess)
                        } else if (path == 'checkAddTask') {
                            method();
                        } else {
                            history.push(path);
                        }
                    }
                } else {
                    AlertModal.showAlert(response.data.message);
                }
            } else {
                logoutUnauthorizedUser(obj, response.data.message);
            }
        } catch (error) {
            spinnerService.hide('Oval');
            AlertModal.showAlert(error.message);
        }
    };

export const getDataWithType =
    (url, type, loaderMsg, obj, language = 'en') =>
    async (dispatch) => {
        spinnerService.show('Oval', loaderMsg);
        try {
            const config = {
                headers: { authorization: `Bearer ${getData('token')}`, language: language }
            };
            let response = await Axios.get(url, config);
            spinnerService.hide('Oval');
            if (response && response.data && response.data.responseCode != 401) {
                if (response && response.data && response.data.responseCode == 200) {
                    dispatch({
                        type,
                        payload: response.data.response
                    });
                } else {
                    AlertModal.showAlert(response.data.message);
                }
            } else {
                logoutUnauthorizedUser(obj, response.data.message);
            }
        } catch (error) {
            spinnerService.hide('Oval');
            AlertModal.showAlert(error.message);
        }
    };

export const verifyAndgenerateReport = async (
    url,
    data,
    assetId,
    loaderMsg,
    fileName,
    obj,
    language = 'en'
) => {
    let lang = LANGUAGECONST[language == 'es' ? 'ESP' : 'ENG'];
    spinnerService.show('Oval', loaderMsg, lang.STRINGS.VERFYINGTOGENERATEREPORT);

    try {
        const config = {
            headers: { authorization: `Bearer ${getData('token')}`, language: language }
        };

        let response = await Axios.post(url, data, config);

        if (response && response.data && response.data.responseCode != 401) {
            if (response && response.data && response.data.responseCode == 200) {
                const reportData = {
                    ...data,
                    assetId
                };

                await Axios({
                    url: `${CONFIG.generateReport}`,
                    method: 'POST',
                    data: reportData, // Use the updated data payload
                    headers: { authorization: `Bearer ${getData('token')}`, language: language }
                }).then((res) => {
                    spinnerService.hide('Oval');
                    AlertModal.showAlert(res.data.message);
                    // spinnerService.hide('Oval');
                    // const url = window.URL.createObjectURL(new Blob([res.data]));
                    // const link = document.createElement('a');
                    // link.href = url;
                    // link.setAttribute('download', fileName);
                    // document.body.appendChild(link);
                    // link.click();
                });
            } else {
                spinnerService.hide('Oval');
                AlertModal.showAlert(response.data.message);
            }
        } else {
            spinnerService.hide('Oval');
            logoutUnauthorizedUser(obj, response.data.message);
        }
    } catch (error) {
        spinnerService.hide('Oval');
        AlertModal.showAlert(error.message);
    }
};

export const downloadTaskRequest = async (url, data, language = 'en') => {
    let lang = LANGUAGECONST[language == 'es' ? 'ESP' : 'ENG'];
    spinnerService.show('Oval', 'Generating the File', lang.STRINGS.DOWNLOADINGTASK);

    try {
        let response = await Axios({
            url: url,
            method: 'POST',
            data: data,
            headers: { authorization: `Bearer ${getData('token')}`, language: language }
        });
        if (response && response.data && response.data.responseCode != 401) {
            spinnerService.hide('Oval');
            AlertModal.showAlert(response.data.message);
        } else {
            spinnerService.hide('Oval');
            logoutUnauthorizedUser(response.data.message);
        }
    } catch (error) {
        spinnerService.hide('Oval');
        AlertModal.showAlert(error.message);
    }
};

export const verifyAndgenerateIntermediateReport = async (
    url,
    data,
    loaderMsg,
    fileName,
    obj,
    language = 'en'
) => {
    let lang = LANGUAGECONST[language == 'es' ? 'ESP' : 'ENG'];
    spinnerService.show('Oval', loaderMsg, lang.STRINGS.VERFYINGTOGENERATEREPORT);
    try {
        // spinnerService.show('Oval', lang.STRINGS.DOWNLOADINGREPORT)
        await Axios({
            url: url,
            method: 'POST',
            data: data,
            headers: { authorization: `Bearer ${getData('token')}`, language: language }
        }).then((res) => {
            spinnerService.hide('Oval');
            AlertModal.showAlert(res.data.message);

            // const url = window.URL.createObjectURL(new Blob([res.data]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', fileName);
            // document.body.appendChild(link);
            // link.click();
        });
    } catch (error) {
        spinnerService.hide('Oval');
        AlertModal.showAlert(error.message);
    }
};

export const downloadFile = async (url, fileName, loaderMsg, authRequired, language = 'en') => {
    try {
        const config = {
            url: `${url}`,
            method: 'GET',
            responseType: 'blob',
            headers: { authorization: `Bearer ${getData('token')}`, language: language }
        };
        if (!authRequired) {
            delete config.headers;
        }
        spinnerService.show('Oval', loaderMsg);
        await Axios(config).then((res) => {
            if (res.data.type.toLowerCase() == 'application/json') {
                spinnerService.hide('Oval');
                AlertModal.showAlert('No Data Found');
            } else if (res.data.type.toLowerCase() == 'text/plain') {
                spinnerService.hide('Oval');
                AlertModal.showAlert('Something Went Wrong');
            } else {
                spinnerService.hide('Oval');
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            }
        });
    } catch (error) {
        spinnerService.hide('Oval');
        AlertModal.showAlert(error.message);
    }
};

export const getPostCostEvaluateValue = async (url, data, type, language = 'en') => {
    let lang = LANGUAGECONST[language == 'es' ? 'ESP' : 'ENG'];
    spinnerService.show('Oval');
    try {
        const config = {
            headers: { authorization: `Bearer ${getData('token')}`, language: language }
        };
        let response =
            type == 'GET'
                ? await Axios.post(url, data, config)
                : await Axios.post(url, data, config);
        spinnerService.hide('Oval');
        // console.log("data",response);
        if (response && response.data && response.data.responseCode != 401) {
            if (response && response.data && response.data.responseCode == 200) {
                return response.data;
            }
            // else {
            //   AlertModal.showAlert(response.data.message);
            // }
        }
    } catch (error) {
        spinnerService.hide('Oval');
        AlertModal.showAlert(error.message);
    }
};

export const getWriteBackValue = async (url, data, type, language = 'en') => {
    let lang = LANGUAGECONST[language == 'es' ? 'ESP' : 'ENG'];
    spinnerService.show('Oval');
    try {
        const config = {
            headers: { authorization: `Bearer ${getData('token')}`, language: language }
        };
        let response =
            type == 'GET'
                ? await Axios.post(url, data, config)
                : await Axios.post(url, data, config);
        spinnerService.hide('Oval');
        // console.log("data",response);
        if (response && response.data && response.data.responseCode != 400) {
            if (response && response.data && response.data.responseCode == 200) {
                return response.data;
            } else {
                AlertModal.showAlert(response.data.message);
            }
        } else {
            return response.data;
        }
    } catch (error) {
        spinnerService.hide('Oval');
        AlertModal.showAlert(error.message);
    }
};
